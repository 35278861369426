import React from 'react';

import Empty from 'components/empty';

import styles from './NoApplications.module.scss';

interface NoApplicationsProps {
  onAddApiKey: () => void;
}

const NoApplications: React.FC<NoApplicationsProps> = ({ onAddApiKey }) => {
  return (
    <Empty>
      <div className={styles.wrapper}>
        <div>You have no API keys.</div>
        <div>
          Please, <button onClick={onAddApiKey}>create application.</button>
        </div>
      </div>
    </Empty>
  );
};

export default NoApplications;
