import React from 'react';

import Header from '../header';
import SideBar from '../side-bar';

import { Children } from 'types/childrenTypes';

import styles from './Layout.module.scss';

interface LayoutProps {
  children: Children;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <main className={styles.container}>
      <Header />
      <SideBar />
      <div className={styles.content}>{children}</div>
    </main>
  );
};

export default Layout;
