import { SLICE_NAME } from 'slices/contactUsModalSlice';
import { RootState } from 'store';

export const contactUsModalStateSelector = (state: RootState) => state[SLICE_NAME];

export const contactUsShowModalSelector = (state: RootState) =>
  contactUsModalStateSelector(state)?.showModal;

export const contactUsModalTypeSelector = (state: RootState) =>
  contactUsModalStateSelector(state)?.modalType;

export const contactUsModalProductIdSelector = (state: RootState) =>
  contactUsModalStateSelector(state)?.productId;
