import React from 'react';

import cx from 'classnames';

import { IconSizeType } from 'types/iconTypes';

import styles from './Icon.module.scss';

interface IconProps {
  src: string;
  alt: string;
  className?: string;
  size?: IconSizeType;
}

const Icon: React.FC<IconProps> = ({ src, alt, className = '', size = 'medium' }) => {
  return (
    <img
      src={src}
      alt={alt}
      className={cx({
        [className]: className,
        [styles[size]]: size,
      })}
    />
  );
};

export default Icon;
