import React, { ReactNode, Ref } from 'react';

import { Input as InputAntd, InputRef } from 'antd';
import cx from 'classnames';

import { InputStatus } from 'types/inputTypes';
import { InputStyleType } from 'types/inputTypes';

import styles from './Input.module.scss';

interface InputProps {
  placeholder?: string;
  name?: string;
  id?: string;
  value?: string;
  handleChange?: (text: string) => void;
  onPressEnter?: (text: string) => void;
  status?: InputStatus;
  maxLength?: number;
  className?: string;
  suffix?: ReactNode;
  disabled?: boolean;
  readOnly?: boolean;
  styleType?: InputStyleType;
  inputType?: 'TextArea' | 'Password' | 'Search' | 'Group';
}

const Input: React.FC<InputProps> = React.forwardRef(
  (
    {
      className = '',
      placeholder,
      value = '',
      name,
      id,
      handleChange,
      onPressEnter,
      status,
      disabled = false,
      readOnly = false,
      styleType = 'outlinedViolet',
      inputType,
      ...otherProps
    },
    ref,
  ) => {
    const Component = inputType ? InputAntd[inputType] : InputAntd;

    return (
      <Component
        ref={ref as Ref<InputRef> | undefined}
        id={id}
        name={name}
        status={status}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleChange && handleChange(e.target.value)}
        onPressEnter={(e) => onPressEnter && onPressEnter(e.target.value)}
        className={cx({
          [styles.input]: true,
          [className]: className,
          [styles.readOnly]: disabled,
          [styles[styleType]]: styleType,
          [styles.textArea]: inputType === 'TextArea',
        })}
        {...otherProps}
      />
    );
  },
);

export default Input;
