import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import Button from 'components/button';
import Input from 'components/input';
import Modal from 'components/modals';
import WithLabel from 'components/with-label';
import WithSpacing from 'components/with-spacing';

import { COMPARE_PLANS_ROUTE } from 'constants/routes';
import { ApiKeyType } from 'types/apiKeyTypes';
import { ProductType } from 'types/productTypes';

import cardAppBackground from 'assets/background/card-app-vector-background.svg';
import { ReactComponent as Pencil } from 'assets/icons/pencil-icon.svg';

import styles from './ModalConfigApp.module.scss';

interface ModalConfigAppProps {
  isAppModalOpened: boolean;
  closeModal: () => void;
  openDeleteModal: () => void;
  cancelChanges: () => void;
  saveChanges: (name: string) => void;
  product?: ProductType;
  currentKey: ApiKeyType;
}

const ModalConfigApp: React.FC<ModalConfigAppProps> = ({
  isAppModalOpened,
  closeModal,
  openDeleteModal,
  cancelChanges,
  saveChanges,
  product,
  currentKey,
}) => {
  const [appName, setAppName] = useState<string>(currentKey.name);

  const onCancel = () => {
    setAppName(currentKey.name);
    cancelChanges();
  };

  return (
    <Modal width={480} visible={isAppModalOpened} handleClose={closeModal}>
      <div className={styles.appModalWrapper}>
        <WithSpacing className={styles.modalHeader} spaceSize="large">
          <Pencil /> Edit App
        </WithSpacing>
        <WithLabel label={'Name'}>
          <Input value={appName} handleChange={setAppName} styleType="outlinedGray" />
        </WithLabel>
        <WithSpacing spaceSize="large" placement="top" className={styles.appSettings}>
          <img src={cardAppBackground} alt="background" className={styles.cardAppBackground} />
          <button className={styles.deleteAppButton} onClick={openDeleteModal}>
            Delete App
          </button>
          <div>
            <img src={product?.logo || ''} alt="plan logo" />
            <span>{product?.name} plan</span>
          </div>
          <div>
            {`${product?.total_api_keys} apps,
              ${product?.total_watchunit.toLocaleString()} watch units per month`}
          </div>
        </WithSpacing>
        <WithSpacing placement="top">
          <Link to={COMPARE_PLANS_ROUTE}>Increase your access x2</Link>
        </WithSpacing>
        <WithSpacing className={styles.buttonsWrapper} placement="top" spaceSize="extra-large">
          <Button styleType="outlinedViolet" handleClick={onCancel}>
            Cancel
          </Button>
          <Button styleType="filledSuccess" handleClick={() => saveChanges(appName)}>
            Save changes
          </Button>
        </WithSpacing>
      </div>
    </Modal>
  );
};

export default ModalConfigApp;
