import React, { useState } from 'react';

import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { userDataSelector } from 'selectors/userSlice.selectors';
import { useSignOutMutation } from 'services/auth/authService';
import { closeDefaultNotification, setShowError } from 'slices/notificationSlice';
import { setIsLoading } from 'slices/userSlice';

import Dropdown from 'components/dropdown';
import PopupUser from 'containers/popup-user';

import { LOGIN_ROUTE } from 'constants/routes';
import { WATCHDATA_REFRESH_TOKEN, WATCHDATA_TOKEN } from 'constants/token';

import { ReactComponent as ArrowBottom } from 'assets/icons/arrow-bottom.svg';
import { ReactComponent as Login } from 'assets/icons/login.svg';

import styles from './User.module.scss';

const User = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const user = useSelector(userDataSelector);

  const [isModalOpen, setModalOpen] = useState(false);

  const [signOut] = useSignOutMutation();

  const logout = () => {
    dispatch(setIsLoading(true));
    dispatch(closeDefaultNotification());
    dispatch(setShowError(false));
    signOut()
      .then((res) => {
        if ('error' in res) {
          console.error('Sign out');
        } else {
          localStorage.removeItem(WATCHDATA_REFRESH_TOKEN);
          localStorage.removeItem(WATCHDATA_TOKEN);
          window.dispatchEvent(new Event('local-storage'));
          navigate(LOGIN_ROUTE);
        }
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };

  return (
    <Dropdown
      getPopupContainer={(e) => e}
      placement="bottomLeft"
      onVisibleChange={setModalOpen}
      visible={isModalOpen}
      overlay={<PopupUser onClickLogout={logout} />}>
      <button
        className={cx({ [styles.user]: true, [styles.userActive]: isModalOpen })}
        onClick={() => {
          setModalOpen((prevState) => !prevState);
        }}>
        <span className={styles.email}>{user?.email}</span>
        <Login className={styles.icon} />
        <ArrowBottom className={styles.iconArrow} />
      </button>
    </Dropdown>
  );
};

export default User;
