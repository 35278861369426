import React, { useCallback, useMemo } from 'react';

import { contactUsModalStateSelector } from 'selectors/contactUsModalSlice.selectors';
import { useLazyContactUsQuery } from 'services/contactUsService';
import { useGetBlockChainTokensForPaymentQuery } from 'services/infoService';
import { setCloseContactUsModal } from 'slices/contactUsModalSlice';
import { setShowError, showDefaultNotification } from 'slices/notificationSlice';
import { useAppDispatch, useAppSelector } from 'store';

import Button from 'components/button';
import Form from 'components/form';
import FormItem from 'components/form/form-item';
import Input from 'components/input';
import Modal from 'components/modals';
import Select from 'components/single-select';
import WithLabel from 'components/with-label';
import WithSpacing from 'components/with-spacing';
import SelectNoData from 'containers/select-no-data';

import styles from './ModalContactUs.module.scss';

type FormSubmitType = {
  firstName: string;
  lastName: string;
  chainsOfInterest?: string;
  token?: string;
};

const SUCCESS_CONTACT_US = {
  text: 'We will contact you as soon as possible to discuss your needs.',
  notificationType: 'success',
};

const ModalContactUs = () => {
  const { showModal, modalType, productId } = useAppSelector(contactUsModalStateSelector);
  const [contactUs, { isFetching }] = useLazyContactUsQuery();
  const dispatch = useAppDispatch();

  const {
    data: blockchains,
    refetch: refetchBlockhain,
    isFetching: blockchainsIsFetching,
  } = useGetBlockChainTokensForPaymentQuery();

  const handleSubmit = useCallback(
    (e: FormSubmitType) => {
      contactUs({
        first_name: e.firstName,
        last_name: e.lastName,
        subject: modalType || '',
        comment: e.chainsOfInterest || `Buy with crypto product ${productId}`,
        token: e.token,
      })
        .then((res) => {
          if (res.isSuccess) {
            dispatch(showDefaultNotification(SUCCESS_CONTACT_US));
          } else {
            dispatch(setShowError(true));
          }
        })
        .finally(() => {
          dispatch(setCloseContactUsModal());
        });
    },
    [contactUs, dispatch, modalType, productId],
  );

  const handleClose = useCallback(() => {
    dispatch(setCloseContactUsModal());
  }, [dispatch]);

  const handleRerunRequest = useCallback(() => {
    refetchBlockhain();
  }, [refetchBlockhain]);

  const noDataComponent = useMemo(
    () => (
      <SelectNoData handleRerunRequest={handleRerunRequest} isLoading={blockchainsIsFetching}>
        <div>It looks like the blockchains didn’t load correctly.</div>
        <div>Please refresh or try again later.</div>
      </SelectNoData>
    ),
    [blockchainsIsFetching, handleRerunRequest],
  );

  return (
    <Modal handleClose={handleClose} visible={showModal} width={480} getContainer={false}>
      <div className={styles.top}>
        <div className={styles.title}>Contact us</div>
        <button className={styles.close} onClick={handleClose}>
          Close
        </button>
      </div>
      <div className={styles.formWrapper}>
        <Form onFinish={handleSubmit} name={'Contact us'}>
          <WithSpacing fullWidth spaceSize="large">
            <WithLabel label="First name" required>
              <FormItem
                name="firstName"
                rules={[{ required: true, message: 'Please input your first name' }]}>
                <Input placeholder="Enter your first name" />
              </FormItem>
            </WithLabel>
          </WithSpacing>

          <WithSpacing fullWidth spaceSize="large">
            <WithLabel label="Last name" required>
              <FormItem
                name="lastName"
                rules={[{ required: true, message: 'Please input your last name' }]}>
                <Input placeholder="Enter your last name" />
              </FormItem>
            </WithLabel>
          </WithSpacing>
          {modalType === 'BUY_WITH_CRYPTO' ? (
            <WithSpacing fullWidth spaceSize="large">
              <WithLabel label="Chains of interest" required>
                <FormItem
                  name="token"
                  rules={[{ required: true, message: 'Please select any token' }]}>
                  <Select
                    placeholder="The most convenient token for payment"
                    options={blockchains as string[]}
                    notFoundContent={noDataComponent}
                  />
                </FormItem>
              </WithLabel>
            </WithSpacing>
          ) : (
            <WithSpacing fullWidth spaceSize="large">
              <WithLabel label="Chains of interest" required>
                <FormItem
                  name="chainsOfInterest"
                  rules={[{ required: true, message: 'Please add some additional info' }]}>
                  <Input
                    inputType="TextArea"
                    placeholder="Tell us more about what you are building"
                  />
                </FormItem>
              </WithLabel>
            </WithSpacing>
          )}
          <FormItem>
            <Button styleType="filled" htmlType="submit" loading={isFetching} size="large">
              Submit
            </Button>
          </FormItem>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalContactUs;
