import React, { useContext } from 'react';

import {
  SimpleCurrentSubscriptionCardContext,
  SimpleCurrentSubscriptionCardContextType,
} from '../simpleCurrentSubscription';

import Button from 'components/button/button';
import WithSkeletonLoader from 'components/with-skeleton-loader';
import PlanElement from 'containers/plan-element';

import { Children } from 'types/childrenTypes';

import calendarImg from 'assets/images/calendar-img.png';

import { convertDateToLocale } from 'utils/convertStringDateToLocale';

import styles from './SimpleCurrentSubscriptionCard.module.scss';
import { SimpleCurrentSubscriptionActionButtonsSkeleton } from './simpleCurrentSubscriptionCard.skeleton';

interface SimpleCurrentSubscriptionCardProps {
  children: Children;
}

const SimpleCurrentSubscriptionCard = ({ children }: SimpleCurrentSubscriptionCardProps) => {
  const context = useContext(SimpleCurrentSubscriptionCardContext);

  if (context === undefined) {
    return null;
  }

  return <div className={styles.wrapper}>{children}</div>;
};

SimpleCurrentSubscriptionCard.Info = function Info() {
  const context = useContext(
    SimpleCurrentSubscriptionCardContext,
  ) as SimpleCurrentSubscriptionCardContextType;

  if (context === undefined) {
    return null;
  }

  const { userCurrentPlan, product, isLoading } = context;

  return (
    <div className={styles.planElementWrapper}>
      <PlanElement
        isLoading={isLoading}
        sectionLabel="Current plan"
        info={product?.name}
        elementIcon={<img src={product.logo} alt="plan icon" />}
      />
      <PlanElement
        isLoading={isLoading}
        sectionLabel="Expiration"
        info={convertDateToLocale(userCurrentPlan.end_date, 'short')}
        elementIcon={<img src={calendarImg} alt="calendar icon" />}
        showBorder={false}
      />
    </div>
  );
};

SimpleCurrentSubscriptionCard.ExpiredInfo = function ExpiredInfo() {
  const context = useContext(
    SimpleCurrentSubscriptionCardContext,
  ) as SimpleCurrentSubscriptionCardContextType;

  if (context === undefined) {
    return null;
  }

  const { userCurrentPlan, product, isLoading } = context;

  return (
    <div className={styles.planElementWrapper}>
      <PlanElement
        isLoading={isLoading}
        sectionLabel="Current plan"
        info={product?.name}
        elementIcon={<img src={product.logo} alt="plan icon" />}
      />
      <PlanElement
        isLoading={isLoading}
        sectionLabelStyle="error"
        sectionLabel="Cancellation date"
        info={convertDateToLocale(userCurrentPlan.end_date, 'short')}
        elementIcon={<img src={calendarImg} alt="calendar icon" />}
        showBorder={false}
      />
    </div>
  );
};

SimpleCurrentSubscriptionCard.ActionButtons = function ActionButtons() {
  const context = useContext(
    SimpleCurrentSubscriptionCardContext,
  ) as SimpleCurrentSubscriptionCardContextType;

  if (context === undefined) {
    return null;
  }

  const { openCancelSubscriptionModal, handleEditCard, isLoading } = context;

  return (
    <div className={styles.buttonsWrapper}>
      <WithSkeletonLoader
        isLoading={isLoading}
        skeletonLoader={<SimpleCurrentSubscriptionActionButtonsSkeleton />}>
        <Button
          theme="black"
          fullWidth={false}
          styleType="outlinedGray"
          size="medium"
          handleClick={handleEditCard}>
          Edit card
        </Button>
        <Button
          theme="black"
          fullWidth={false}
          styleType="outlinedTransparent"
          handleClick={openCancelSubscriptionModal}
          size="medium">
          Cancel subscription
        </Button>
      </WithSkeletonLoader>
    </div>
  );
};

SimpleCurrentSubscriptionCard.ExpiredActionButtons = function ExpiredActionButtons() {
  const context = useContext(
    SimpleCurrentSubscriptionCardContext,
  ) as SimpleCurrentSubscriptionCardContextType;

  if (context === undefined) {
    return null;
  }

  const { handleRenewSubscription, userCurrentPlan, isLoading } = context;

  return (
    <div className={styles.buttonsWrapper}>
      <WithSkeletonLoader
        isLoading={isLoading}
        skeletonLoader={<SimpleCurrentSubscriptionActionButtonsSkeleton />}>
        <Button
          theme="black"
          fullWidth={false}
          styleType="outlinedGray"
          size="medium"
          handleClick={() => handleRenewSubscription(userCurrentPlan.id)}>
          Renew subscription
        </Button>
      </WithSkeletonLoader>
    </div>
  );
};

export default SimpleCurrentSubscriptionCard;
