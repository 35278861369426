import React from 'react';

import cx from 'classnames';
import { Link } from 'react-router-dom';

import Icon from 'components/icon';
import User from 'containers/user';

import { HEADER_VIEW_DOCUMENTATION_LINK } from 'constants/productFruitsTags';
import { HOME_ROUTE } from 'constants/routes';

import ImgDoc from 'assets/icons/document.png';
import logo from 'assets/icons/logo.svg';

import styles from './Header.module.scss';

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.wrapper}>
        <div className={styles.col}>
          <div className={styles.logo}>
            <Link to={HOME_ROUTE}>
              <img src={logo} alt="WhatchData logo" width="146" height="24" />
            </Link>
          </div>
          {/* Doesnt exist on current mockups */}
          {/*<a className={cx(styles.link, styles.linkBack)} href="https://www.watchdata.io/">*/}
          {/*  <Arrow />*/}
          {/*  <span> Back to the site</span>*/}
          {/*</a>*/}
        </div>
        <div className={cx(styles.col, styles.colRight)}>
          <a
            data-id={HEADER_VIEW_DOCUMENTATION_LINK}
            className={cx(styles.link, styles.linkDoc)}
            href="https://docs.watchdata.io/"
            target="_blank">
            <Icon src={ImgDoc} alt="Document" size="medium" />
            Documentation
          </a>
          <User />
        </div>
      </div>
    </header>
  );
};

export default Header;
