import React, { memo } from 'react';

import { Select } from 'antd';

import { Primitive } from 'types/baseTypes';
import { Children } from 'types/childrenTypes';

const { Option: OptionAntd } = Select;

interface OptionProps {
  label: Primitive;
  value: Primitive;
  children: Children;
  className?: string;
}

const SelectOption: React.FC<OptionProps> = ({ label, value, children, className }) => {
  return (
    <OptionAntd value={value} label={label} className={className} disabled>
      {children}
    </OptionAntd>
  );
};

export default memo(SelectOption);
