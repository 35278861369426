import React from 'react';

import { Tooltip as AntdTooltip } from 'antd';

import { Children } from 'types/childrenTypes';
import { TooltipPlacementTypes, TooltipTriggerTypes } from 'types/tooltipTypes';

import 'antd/lib/tooltip/style/css';

interface TooltipProps {
  children: Children;
  placement?: TooltipPlacementTypes;
  overlay?: React.ReactElement;
  title?: string;
  trigger?: TooltipTriggerTypes[];
}

const Tooltip: React.FC<TooltipProps> = ({
  children,
  placement = 'top',
  overlay,
  title,
  trigger = ['hover'],
}) => {
  return (
    <AntdTooltip
      placement={placement}
      overlay={overlay}
      title={title}
      trigger={trigger}
      color="white"
      overlayInnerStyle={{
        color: '#091834',
        padding: '0.75rem',
        fontWeight: 400,
        fontSize: '0.75rem',
      }}>
      {children}
    </AntdTooltip>
  );
};

export default Tooltip;
