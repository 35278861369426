import React, { FC } from 'react';

import Button from 'components/button';
import Modal from 'components/modals';

import { ReactComponent as EditIcon } from 'assets/icons/edit-icon.svg';
import { ReactComponent as LogoIcon } from 'assets/icons/logo-without-name.svg';

import s from './LoginCheckEmail.module.scss';

interface LoginCheckEmailProps {
  isVisible: boolean;
  handleClose: () => void;
  email: string;
}
export const FIND_EMAIL_LINK = `https://mail.google.com/mail/u/0/#search/from%3A%40${process.env.REACT_APP_CONFIRMATION_EMAIL}+in%3Aanywhere`;

const LoginCheckEmail: FC<LoginCheckEmailProps> = (props) => {
  const { handleClose, isVisible, email } = props;

  return (
    <Modal handleClose={handleClose} visible={isVisible} width={400}>
      <div className={s.wrapper}>
        <LogoIcon />
        <h2>Check your email</h2>
        <div className={s.info}>
          <h3>We emailed a link to</h3>
          <button onClick={handleClose}>
            <h3>{email}</h3>
            <EditIcon />
          </button>
          <h3>Click the link to log in</h3>
        </div>
        <Button
          styleType="filled"
          handleClick={() => window.open(FIND_EMAIL_LINK, '_blank')}
          size="large"
          fullWidth>
          Check your inbox
        </Button>
      </div>
    </Modal>
  );
};

export default LoginCheckEmail;
