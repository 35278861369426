import initialApiService from 'services/initialService';

import { PaginationType } from 'types/paginationTypes';
import { PaymentType } from 'types/paymentTypes';
import { getPortalParamsType, getSessionParamsType } from 'types/stripeTypes';

export const paymentApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    deactivateSession: builder.mutation({
      query: (id: string | number) => ({
        url: 'payment/stripe/deactivate',
        method: 'DELETE',
        body: { id },
      }),
    }),
    reactivateSession: builder.mutation({
      query: (id: string | number) => ({
        url: 'payment/stripe/reactivate',
        method: 'PUT',
        body: { id },
      }),
    }),
    getSession: builder.query({
      query: (params: getSessionParamsType) => ({
        url: 'payment/stripe/create_checkout_session',
        method: 'POST',
        // TODO remove ID later need only for BE for now
        body: { ...params, subscribe_model: { id: 3, ...params.subscribe_model } },
      }),
    }),
    getPortal: builder.query({
      query: (params: getPortalParamsType) => ({
        url: 'payment/stripe/create_portal_session',
        method: 'POST',
        body: params,
      }),
    }),
    getPaymentHistory: builder.query<{ payments: PaymentType[]; pagination: PaginationType }, void>(
      {
        query: () => ({
          url: 'payment/history',
        }),
      },
    ),
    getCsv: builder.query<PaymentType[], void>({
      query: () => ({
        url: 'payment/csv',
        responseHandler: async (response) =>
          window.location.assign(window.URL.createObjectURL(await response.blob())),
      }),
    }),
  }),
});

export const {
  useLazyGetSessionQuery,
  useLazyGetPortalQuery,
  useLazyGetCsvQuery,
  useGetPaymentHistoryQuery,
  useDeactivateSessionMutation,
  useReactivateSessionMutation,
} = paymentApiService;
