import React, { memo, useCallback } from 'react';

import { Pagination as PaginationAntd } from 'antd';
import cx from 'classnames';

import 'antd/lib/pagination/style/css';

import styles from './Pagination.module.scss';

interface PaginationProps {
  total?: number;
  current?: number;
  className?: string;
  defaultPageSize?: number;
  pageSize?: number;
  onChange?: (page: number, pageSize: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  total = 0,
  defaultPageSize = 10,
  pageSize = 10,
  className = '',
  onChange,
  current,
}): React.ReactElement => {
  const onHandleChange = useCallback(
    (newPage: number, newPageSize: number) => {
      if (onChange) {
        onChange(newPage, newPageSize);
      }
    },
    [onChange],
  );

  if (!pageSize) {
    return <></>;
  }

  return (
    <PaginationAntd
      current={current}
      className={cx({ [styles.wrapper]: true, [className]: className })}
      total={total}
      defaultPageSize={defaultPageSize}
      pageSize={pageSize}
      onChange={onHandleChange}
      showSizeChanger={false}
    />
  );
};

export default memo(Pagination);
