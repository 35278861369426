export const BUTTON_LABEL: { [key: string]: string } = {
  DEFAULT: 'Default plan',
  GET_STARTED: 'Get started',
  CONTACT_US: 'Contact us',
  RENEW_SUBSCRIPTION: 'Renew Subscription',
};

export const TYPES = {
  POPULAR: 'POPULAR',
  DEFAULT: 'DEFAULT',
};
