import { createSlice } from '@reduxjs/toolkit';

import { ModalType } from 'types/contactUsModalTypes';

export const SLICE_NAME = 'contactUsModalSlice';

type ContactUsModalSliceState = {
  showModal: boolean;
  modalType: ModalType | null;
  productId: string | number | null;
};

const initialState: ContactUsModalSliceState = {
  showModal: false,
  modalType: null,

  // productIds are used for BUY_WITH_CRYPTO logic
  productId: null,
};

export const contactUsModalSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setOpenContactUsModal(state, action) {
      state.showModal = true;
      state.modalType = action.payload.modalType;
      state.productId = action.payload.productId ?? null;
    },
    setCloseContactUsModal(state) {
      state.showModal = false;
      state.modalType = null;
      state.productId = null;
    },
  },
});

export const { setOpenContactUsModal, setCloseContactUsModal } = contactUsModalSlice.actions;

export default contactUsModalSlice.reducer;
