import React from 'react';

import { Children } from 'types/childrenTypes';

interface WithSkeletonLoaderProps {
  children: Children;
  isLoading: boolean;
  skeletonLoader: React.ReactElement;
}

const WithSkeletonLoader: React.FC<WithSkeletonLoaderProps> = ({
  children,
  isLoading,
  skeletonLoader,
}): React.ReactElement => {
  if (isLoading) {
    return skeletonLoader;
  }

  return <>{children}</>;
};

export default WithSkeletonLoader;
