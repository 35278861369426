import { createSlice } from '@reduxjs/toolkit';

import { notificationTypes } from 'types/stripeTypes';

export const SLICE_NAME = 'notificationSlice';

type NotificationSliceState = {
  showError: boolean;
  notificationType: notificationTypes | '';
  defaultNotificationText: string;
  showDefaultNotification: boolean;
};

const initialState: NotificationSliceState = {
  showError: false,
  notificationType: '',
  defaultNotificationText: '',
  showDefaultNotification: false,
};

export const notificationSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setShowError(state, action) {
      state.showError = action.payload;
    },
    showDefaultNotification(state, action) {
      state.defaultNotificationText = action.payload.text;
      state.notificationType = action.payload.notificationType;
      state.showDefaultNotification = true;
    },
    closeDefaultNotification(state) {
      state.defaultNotificationText = '';
      state.notificationType = '';
      state.showDefaultNotification = false;
    },
  },
});

export const { setShowError, showDefaultNotification, closeDefaultNotification } =
  notificationSlice.actions;

export default notificationSlice.reducer;
