import React, { memo, useState } from 'react';

import { Switch as SwitchAntd } from 'antd';
import cx from 'classnames';

import WithSpacing from 'components/with-spacing';

import { Primitive } from 'types/baseTypes';

import 'antd/lib/switch/style/css';

import styles from './Switch.module.scss';

interface SwitchProps {
  disabled?: boolean;
  loading?: boolean;
  onChange: (isChecked: boolean) => void;
  checked: boolean;
  className?: string;
  labels: [Primitive, Primitive];
}

const Switch: React.FC<SwitchProps> = ({
  onChange,
  className = '',
  labels,
  checked,
  loading = false,
  disabled = false,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const [firstLabel, secondLabel] = labels;

  return (
    <div className={styles.wrapper}>
      <span className={cx({ [styles.isChecked]: !isChecked })}>{firstLabel}</span>
      <WithSpacing placement="left-right" spaceSize="medium">
        <SwitchAntd
          disabled={disabled}
          loading={loading}
          checked={checked}
          onChange={(isChecked) => {
            onChange(isChecked);
            setIsChecked(isChecked);
          }}
          className={cx({
            [className]: className,
            [styles.switcherChecked]: isChecked,
          })}
        />
      </WithSpacing>
      <span className={cx({ [styles.isChecked]: isChecked })}>{secondLabel}</span>
    </div>
  );
};

export default memo(Switch);
