import { SLICE_NAME } from 'slices/notificationSlice';
import { RootState } from 'store';

export const notificationStateSelector = (state: RootState) => state[SLICE_NAME];

export const notificationShowErrorSelector = (state: RootState) =>
  notificationStateSelector(state)?.showError;

export const notificationDefaultNotificationTextSelector = (state: RootState) =>
  notificationStateSelector(state)?.defaultNotificationText;

export const notificationNotificationTypeSelector = (state: RootState) =>
  notificationStateSelector(state)?.notificationType;

export const notificationShowDefaultNotificationSelector = (state: RootState) =>
  notificationStateSelector(state)?.showDefaultNotification;
