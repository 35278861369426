import React from 'react';

import EmptyIcon from 'assets/icons/empty.svg';

import styles from './Empty.module.scss';

interface EmptyProps {
  children: React.ReactElement | string | null;
}

const Empty: React.FC<EmptyProps> = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <img src={EmptyIcon} alt="empty icon" />
      {children}
    </div>
  );
};

export default Empty;
