import React, { Suspense, memo } from 'react';

import Info from 'containers/info';

import styles from './MySubscription.module.scss';

const CurrentSubscription = React.lazy(() => import('./current-subscription'));
const PastInvoices = React.lazy(() => import('./past-invoices'));

const MySubscription = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.subscriptionWrapper}>
        <Suspense>
          <CurrentSubscription />
        </Suspense>
        <Suspense>
          <PastInvoices />
        </Suspense>
      </div>
      <Info />
    </div>
  );
};

export default memo(MySubscription);
