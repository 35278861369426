import React from 'react';

import cx from 'classnames';
import styles from 'pages/app-view/app_card/AppCard.module.scss';

import Skeleton from 'components/skeleton';
import WithSpacing from 'components/with-spacing';

interface PlanElementSkeletonProps {
  showIcon?: boolean;
  showBorder?: boolean;
}

export const PlanElementSkeleton: React.FC<PlanElementSkeletonProps> = ({
  showIcon = false,
  showBorder = false,
}) => {
  return (
    <div className={styles.wrapperPlanElementSkeleton}>
      <WithSpacing spaceSize="large">
        <Skeleton style={{ width: 65, height: 14 }} />
      </WithSpacing>
      <div>
        {showIcon && <Skeleton type="Avatar" style={{ height: 24, width: 24 }} />}
        <div className={cx({ [styles.info]: showBorder })}>
          <Skeleton style={{ width: 100, height: showIcon ? 16 : 22 }} />
        </div>
      </div>
    </div>
  );
};
