import React, { memo } from 'react';

import Button from 'components/button';
import Spinner from 'components/spinner';

import { Children } from 'types/childrenTypes';

import styles from './selectNoData.module.scss';

interface SelectNoDataProps {
  isLoading: boolean;
  rerunButtonLabel?: string;
  handleRerunRequest: () => void;
  children: Children;
}

const SelectNoData: React.FC<SelectNoDataProps> = ({
  isLoading,
  rerunButtonLabel = 'Refresh',
  handleRerunRequest,
  children,
}) => {
  const content = isLoading ? (
    <Spinner />
  ) : (
    <>
      {children}
      <Button styleType="filled" size="small" handleClick={handleRerunRequest} fullWidth={false}>
        {rerunButtonLabel}
      </Button>
    </>
  );

  return <div className={styles.noDataWrapper}>{content}</div>;
};

export default memo(SelectNoData);
