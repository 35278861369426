import React, { memo } from 'react';

import { Button as AntButton } from 'antd';
import cx from 'classnames';

import {
  ButtonHtmlType,
  ButtonSizeType,
  ButtonStyleType,
  ButtonThemeType,
  ButtonType,
} from 'types/buttonTypes';
import { Children } from 'types/childrenTypes';

import styles from './Button.module.scss';

export interface ButtonProps {
  className?: string;
  id?: string;
  type?: ButtonType;
  fullWidth?: boolean;
  handleClick?: () => void;
  children: Children;
  loading?: boolean;
  disabled?: boolean;
  styleType?: ButtonStyleType;
  htmlType?: ButtonHtmlType;
  size?: ButtonSizeType;
  theme?: ButtonThemeType;
  dataId?: string;
}

export interface LoaderProps {
  styleType?: ButtonStyleType;
  theme?: ButtonThemeType;
}

const Loader: React.FC<LoaderProps> = memo(({ theme, styleType }) => {
  const showGrayLoader = theme === 'white' && styleType !== 'filled';

  return (
    <div className={styles.loaderContainer}>
      <span
        className={cx({
          [styles.loader]: true,
          [styles.loaderGray]: showGrayLoader,
        })}
      />
    </div>
  );
});

const Button: React.FC<ButtonProps> = ({
  id,
  handleClick,
  children,
  loading = false,
  disabled,
  fullWidth = true,
  htmlType = 'button',
  styleType = 'outlinedGray',
  type = 'text',
  size = 'large',
  theme = 'white',
  dataId,
}) => {
  const onClick = () => {
    if (handleClick) {
      handleClick();
    }
  };
  const content = loading ? <Loader theme={theme} styleType={styleType} /> : children;

  return (
    <AntButton
      id={id}
      className={cx({
        [styles.button]: true,
        [styles.loading]: loading,
        [styles[size]]: size,
        [styles[theme]]: theme,
        [styles.fullWidth]: fullWidth,
        [styles[styleType]]: styleType,
      })}
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      htmlType={htmlType}
      data-id={dataId}>
      {content}
    </AntButton>
  );
};

export default memo(Button);
