import React, { useCallback } from 'react';

import { notificationDefaultNotificationTextSelector } from 'selectors/notificationSlice.selectors';
import { closeDefaultNotification } from 'slices/notificationSlice';
import { useAppDispatch, useAppSelector } from 'store';

import Notification from 'components/notification';

import styles from './NotificationError.module.scss';

const NotificationError = () => {
  const dispatch = useAppDispatch();
  const text = useAppSelector(notificationDefaultNotificationTextSelector);

  const onClose = useCallback(() => {
    dispatch(closeDefaultNotification());
  }, [dispatch]);

  return (
    <Notification type="error" className={styles.wrapper} onClose={onClose}>
      {text}
    </Notification>
  );
};

export default NotificationError;
