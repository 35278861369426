import React, { memo } from 'react';

import Icon from 'components/icon';

import { BlockchainType } from 'types/blockchainTypes';

import styles from './Blockchain.module.scss';

interface BlockchainProps {
  blockchain: BlockchainType;
}

const Blockchain: React.FC<BlockchainProps> = ({ blockchain }) => {
  if (!blockchain) {
    return null;
  }

  return (
    <div className={styles.blockchain}>
      <Icon src={blockchain.icon} alt={`${blockchain.label} icon`} size="large" />
      {blockchain.label}
    </div>
  );
};

export default memo(Blockchain);
