
export const convertDateToLocale = (date: string | Date | null | undefined, month: 'short' | 'long') => {
  if(!date) {
    return ''
  }

  return new Date(date)?.toLocaleDateString(
    window.navigator.language,
    {
      day: 'numeric',
      month,
      year: 'numeric',
    },
  )
}
