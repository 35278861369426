import React from 'react';

import { setShowError } from 'slices/notificationSlice';
import { useAppDispatch } from 'store';

import Notification from 'components/notification';

import styles from './NotificationRequestError.module.scss';

const NotificationRequestError = () => {
  const dispatch = useAppDispatch();
  const closeError = () => dispatch(setShowError(false));
  const reloadPage = () => window.location.reload();

  return (
    <Notification type="error" className={styles.wrapper} onClose={closeError}>
      <div>
        Ooops! Something went wrong. Please
        <button onClick={reloadPage}>reload the page</button>
        and try again.
      </div>
    </Notification>
  );
};

export default NotificationRequestError;
