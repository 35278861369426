import React, { useCallback, useState } from 'react';

import { useGetApiKeysQuery } from 'services/userService';

import Button from 'components/button/button';
import Table from 'components/table';
import ApiKeyRow from 'containers/api-key-row';

import { ApiKeyType } from 'types/apiKeyTypes';
import { Align } from 'types/baseTypes';

import { ReactComponent as NoDataIcon } from 'assets/icons/no-data-icon.svg';

import styles from './AppsTable.module.scss';
import NoApplications from './no-applications';

interface AppsTableProps {
  onAddApiKey: () => void;
  handleRerunRequest: () => void;
}

const columns: { label: string; align?: Align }[] = [
  {
    label: 'App',
    align: 'left',
  },
  {
    label: 'Network',
    align: 'left',
  },
  {
    label: 'API key',
    align: 'left',
  },
  {
    label: 'More information',
    align: 'center',
  },
];
const loadingArray = Array.from({ length: 3 }, (_, i) => i);
const CURRENT_LIMIT = 25;

const AppsTable: React.FC<AppsTableProps> = ({
  onAddApiKey,
  handleRerunRequest,
}): React.ReactElement => {
  const [offset, setOffset] = useState<number>(0);
  const {
    isFetching: keysIsLoading,
    data: apiKeys,
    isError: apiKeysError,
  } = useGetApiKeysQuery({ limit: CURRENT_LIMIT, offset });

  const handlePaginationChange = useCallback((page: number) => {
    return setOffset((page - 1) * CURRENT_LIMIT);
  }, []);

  if (keysIsLoading) {
    return (
      <Table
        className={styles.wrapper}
        columns={columns}
        renderItem={(item: number) => <ApiKeyRow item={item} key={item} isLoading />}
        items={loadingArray}
      />
    );
  }

  const ErrorDataComponent = () => {
    return (
      <div className={styles.errorWrapper}>
        <NoDataIcon />
        <div>It looks like the data didn’t load correctly.</div>
        <div>Please refresh or try again later.</div>
        <Button
          handleClick={handleRerunRequest}
          fullWidth={false}
          size="medium"
          styleType="outlinedViolet">
          Refresh
        </Button>
      </div>
    );
  };

  if (!apiKeys?.data?.length) {
    return <NoApplications onAddApiKey={onAddApiKey} />;
  }

  return (
    <Table
      className={styles.wrapper}
      columns={columns}
      renderItem={(item: ApiKeyType, index: number) => (
        <ApiKeyRow item={item} key={item.id} index={index} />
      )}
      isError={apiKeysError}
      items={apiKeys?.data || []}
      noDataComponent={<NoDataIcon />}
      limit={apiKeys?.pagination?.limit}
      offset={apiKeys?.pagination?.offset}
      totalCount={apiKeys?.pagination?.count}
      errorComponent={<ErrorDataComponent />}
      handlePaginationChange={handlePaginationChange}
    />
  );
};

export default AppsTable;
