import React from 'react';

import { Modal as ModalAntd } from 'antd';

import { Children } from 'types/childrenTypes';

import 'antd/lib/modal/style/index.css';

import styles from './Modal.module.scss';

type getContainerFunc = () => HTMLElement;

interface ModalProps {
  width: number;
  children: Children;
  visible: boolean;
  closable?: boolean;
  handleClose: () => void;
  getContainer?: string | HTMLElement | getContainerFunc | false;
}

const Modal: React.FC<ModalProps> = ({
  width = 562,
  children,
  visible,
  handleClose,
  closable = false,
  getContainer,
}) => {
  if (!visible) {
    return null;
  }

  return (
    <ModalAntd
      getContainer={getContainer}
      className={styles.modal}
      maskStyle={{ backgroundColor: 'rgba(19,19,20,.8)' }}
      width={width}
      visible={visible}
      closable={closable}
      onCancel={handleClose}
      maskClosable
      centered>
      {children}
    </ModalAntd>
  );
};

export default Modal;
