import React, { memo, useCallback, useMemo, useState } from 'react';

import cx from 'classnames';

import { setOpenContactUsModal } from 'slices/contactUsModalSlice';
import { useAppDispatch } from 'store';

import Button from 'components/button';
import Dropdown from 'components/dropdown';
import Icon from 'components/icon';
import WithSkeletonLoader from 'components/with-skeleton-loader';
import WithSpacing from 'components/with-spacing';
import PayOverlay from 'containers/pay-overlay';
import ProductSkeleton from 'containers/product/product.skeleton';

import { CURRENCY } from 'constants/currency';
import { BUTTON_LABEL, TYPES } from 'constants/productButton';
import { ButtonThemeType } from 'types/buttonTypes';
import { ProductType } from 'types/productTypes';

import popularChip from 'assets/icons/popular-chip-icon.svg';

import styles from './Product.module.scss';

export interface ProductProps extends ProductType {
  disabled: boolean;
  className?: string;
  theme: ButtonThemeType;
  buyWithCrypto: (id: string) => void;
  buyWithFiat: (id: string) => void;
  isLoading?: boolean;
}

const Product: React.FC<Partial<ProductProps>> = ({
  name,
  is_can_buy,
  current_price,
  description,
  logo,
  currency,
  className = '',
  type = '',
  disabled,
  total_watchunit,
  watchunit_per_second,
  total_api_keys,
  theme = 'white',
  buyWithCrypto,
  buyWithFiat,
  id,
  isLoading,
}) => {
  const [isPayOverlayOpen, setIsPayOverlayOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const getActionButton = useCallback(
    (label: string, handleClick?: () => void): React.ReactElement => (
      <Button
        theme={theme}
        styleType="filled"
        size="large"
        disabled={disabled}
        handleClick={handleClick}>
        {label}
      </Button>
    ),
    [theme, disabled],
  );

  const handleCryptoPay = useCallback(() => {
    if (buyWithCrypto && id) {
      buyWithCrypto(id);
      setIsPayOverlayOpen(false);
    }
  }, [buyWithCrypto, id]);
  const handleFiatPay = useCallback(() => {
    if (buyWithFiat && id) {
      buyWithFiat(id);
      setIsPayOverlayOpen(false);
    }
  }, [buyWithFiat, id]);

  const buttonWithWrapper = useMemo((): React.ReactElement => {
    if (is_can_buy === null) {
      return <>{getActionButton(BUTTON_LABEL.DEFAULT)}</>;
    }

    return is_can_buy ? (
      <Dropdown
        overlay={<PayOverlay handleCryptoPay={handleCryptoPay} handleFiatPay={handleFiatPay} />}
        disabled={disabled}
        getPopupContainer={(e) => e}
        placement="top"
        onVisibleChange={setIsPayOverlayOpen}
        visible={isPayOverlayOpen}>
        {getActionButton(BUTTON_LABEL.GET_STARTED, () =>
          setIsPayOverlayOpen((prevState) => !prevState),
        )}
      </Dropdown>
    ) : (
      <>
        {getActionButton(BUTTON_LABEL.CONTACT_US, () => {
          dispatch(
            setOpenContactUsModal({
              modalType: 'BUY_ENTERPRISE',
            }),
          );
        })}
      </>
    );
  }, [
    disabled,
    dispatch,
    getActionButton,
    handleCryptoPay,
    handleFiatPay,
    isPayOverlayOpen,
    is_can_buy,
  ]);

  const features = (
    <ul className={cx({ [styles.list]: true, [styles[theme]]: theme })}>
      {!!total_watchunit && <li>{`Watch units per month: ${total_watchunit.toLocaleString()}`}</li>}
      {!!watchunit_per_second && (
        <li>{`Watch units per sec: ${watchunit_per_second.toLocaleString()}`}</li>
      )}
      {!!total_api_keys && <li>{`${total_api_keys.toLocaleString()} API keys`}</li>}
    </ul>
  );

  const calculatedCurrency = currency ? CURRENCY[currency] || currency : '$';
  const calculatedPrice = (current_price ?? 0).toLocaleString();
  const priceWithCurrency = is_can_buy === false ? null : `${calculatedCurrency}${calculatedPrice}`;

  return (
    <WithSkeletonLoader isLoading={!!isLoading} skeletonLoader={<ProductSkeleton />}>
      <div
        className={cx({ [styles.product]: true, [className]: className, [styles[theme]]: theme })}>
        {type === TYPES.POPULAR && (
          <div className={styles.chip}>
            <img src={popularChip} alt="popular chip" />
            <span>Popular</span>
          </div>
        )}
        <WithSpacing
          className={cx({ [styles.name]: true, [styles[theme]]: theme })}
          spaceSize="large">
          <Icon src={logo || ''} alt="price logo" size="large" />
          <span>{name}</span>
        </WithSpacing>
        <WithSpacing spaceSize="large" placement="bottom">
          {description || ''}
        </WithSpacing>
        <WithSpacing spaceSize="large" className={styles.price} placement="bottom">
          {priceWithCurrency}
        </WithSpacing>
        <WithSpacing spaceSize="large" className={styles.fullWidth} placement="bottom">
          {buttonWithWrapper}
        </WithSpacing>
        {features}
      </div>
    </WithSkeletonLoader>
  );
};

export default memo(Product);
