import { useCallback, useEffect, useState } from 'react';

import isEqual from 'lodash.isequal';
import reduce from 'lodash.reduce';
import { ParamKeyValuePair, useSearchParams } from 'react-router-dom';

import { MULTI_QUERY_SEPARATOR, QUERY_VALUE_SEPARATOR } from 'constants/query';
import { ParamsObjectType, ParamsType } from 'types/paramsTypes';
import { QueryTypes } from 'types/queryTypes';

export const transformQueryIntoObject = (keyWithValue: string) => {
  const [key, value] = keyWithValue.split(QUERY_VALUE_SEPARATOR);
  return { [key]: value };
};

export const transformObjectToQueryString = (val: { [key: string]: string | number }) => {
  const y = Object.entries(val)[0];
  return `${y[0]}${QUERY_VALUE_SEPARATOR}${y[1]}`;
};

const prepareQuery = (paramsObject?: {
  [key: string]: ParamsType;
}): { [key: string]: ParamsType } => {
  if (!paramsObject) {
    return {};
  }

  return reduce(
    paramsObject,
    (prevVal: { [key: string]: ParamsType }, curVal, key: string) => {
      if (Array.isArray(curVal)) {
        prevVal[key] = curVal.map(transformObjectToQueryString).join(MULTI_QUERY_SEPARATOR);
      } else {
        prevVal[key] = curVal;
      }

      return prevVal;
    },
    {},
  );
};

const useQueryParamsHook = () => {
  const [paramsObject, setParamsObject] = useState<ParamsObjectType | undefined>();
  const [searchParams, setSearch] = useSearchParams();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    const updatedParams: ParamsObjectType = {};
    for (const [key, value] of searchParams.entries()) {
      if (value.includes(MULTI_QUERY_SEPARATOR)) {
        updatedParams[key] = value.split(MULTI_QUERY_SEPARATOR).map(transformQueryIntoObject);
      } else {
        updatedParams[key] = value.toString();
      }
    }

    if (!isEqual(paramsObject, updatedParams)) {
      setParamsObject(updatedParams);
    }
    if (!isInitialized) {
      setIsInitialized(true);
    }
  }, [isInitialized, paramsObject, searchParams]);

  const setUpdatedSearch = useCallback(
    (newQueries: { [key: string]: string }, shouldRewrite?: boolean) => {
      const newQuery = shouldRewrite
        ? prepareQuery(newQueries)
        : { ...prepareQuery(paramsObject), ...prepareQuery(newQueries) };

      setSearch(newQuery as unknown as ParamKeyValuePair[]);
    },
    [paramsObject, setSearch],
  );
  return { paramsObject, setQueryParams: setUpdatedSearch, isInitialized } as {
    isInitialized: boolean;
    paramsObject: { [key: string]: string } | undefined;
    setQueryParams: (newQueries: QueryTypes, shouldRewrite?: boolean) => void;
  };
};

export default useQueryParamsHook;
