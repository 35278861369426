import React from 'react';

import { Link } from 'react-router-dom';

import styles from './PopupUser.module.scss';

interface PopupUserProps {
  onClickLogout: () => void;
}

const PopupUser: React.FC<PopupUserProps> = ({ onClickLogout }) => {
  return (
    <div className={styles.popup}>
      <div className={styles.popupWrapper}>
        <Link to="#" onClick={onClickLogout}>
          Log out
        </Link>
      </div>
    </div>
  );
};

export default PopupUser;
