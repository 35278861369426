import React from 'react';

import { Link } from 'react-router-dom';

import SectionLabel from 'components/section-label';
import WithSkeletonLoader from 'components/with-skeleton-loader';
import WithSpacing from 'components/with-spacing';

import { COMPARE_PLANS_ROUTE, HOME_ROUTE } from 'constants/routes';

import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as Lightning } from 'assets/icons/lightning-icon.svg';

import styles from './AppViewHeader.module.scss';
import AppViewHeaderSkeleton from './appViewHeader.skeleton';

interface AppViewHeaderProps {
  name?: string;
  isLoading: boolean;
}

const AppViewHeader: React.FC<AppViewHeaderProps> = ({ name, isLoading }) => {
  return (
    <WithSpacing className={styles.wrapper} spaceSize="large">
      <div>
        <WithSpacing placement="right" spaceSize="large">
          <Link to={HOME_ROUTE}>
            <ArrowLeft />
          </Link>
        </WithSpacing>
        <WithSkeletonLoader isLoading={isLoading} skeletonLoader={<AppViewHeaderSkeleton />}>
          <SectionLabel>{name || ''}</SectionLabel>
        </WithSkeletonLoader>
      </div>
      <Link to={COMPARE_PLANS_ROUTE}>
        <Lightning />
        Upgrade plan
      </Link>
    </WithSpacing>
  );
};

export default AppViewHeader;
