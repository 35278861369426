import React, { memo, useCallback, useMemo } from 'react';

import { useGetBlockChainListQuery } from 'services/infoService';
import { useAddApiKeyMutation, useLazyGetApiKeysQuery } from 'services/userService';

import Button from 'components/button';
import Form from 'components/form';
import FormItem from 'components/form/form-item';
import Input from 'components/input';
import Modal from 'components/modals';
import SingleSelect from 'components/single-select';
import SelectNoData from 'containers/select-no-data';

import { CREATE_API_MODAL_BUTTON } from 'constants/analyticsTags';
import { BlockchainType } from 'types/blockchainTypes';

import styles from './ModalCreateApp.module.scss';

interface ModalCreateKeyProps {
  onClose: () => void;
  visible: boolean;
}

const ModalCreateApp: React.FC<ModalCreateKeyProps> = ({ onClose, visible }) => {
  const {
    data: options = [],
    refetch: refetchBlockhain,
    isFetching: blockchainsIsFetching,
  } = useGetBlockChainListQuery();

  const [form] = Form.useForm();

  const [addApiKey, { isLoading: addKeyLoading }] = useAddApiKeyMutation();
  const [getApiKeys, { isFetching: getKeysLoading }] = useLazyGetApiKeysQuery();

  const selectOptions = options.map((option: BlockchainType) => ({
    label: option.name,
    value: option.symbol,
    icon: option.icon_url,
  }));

  const handleSubmit = (formValues: { name: string; blockchain: string }) => {
    const { name, blockchain } = formValues;

    addApiKey({
      name,
      blockchain,
    })
      .then(() => {
        onClose();
        getApiKeys();
      })
      .finally(() => {
        form.resetFields(['name', 'blockchain']);
      });
  };

  const handleRerunRequest = useCallback(() => {
    refetchBlockhain();
  }, [refetchBlockhain]);

  const noDataComponent = useMemo(
    () => (
      <SelectNoData handleRerunRequest={handleRerunRequest} isLoading={blockchainsIsFetching}>
        <div>It looks like the blockchains didn’t load correctly.</div>
        <div>Please refresh or try again later.</div>
      </SelectNoData>
    ),
    [blockchainsIsFetching, handleRerunRequest],
  );

  return (
    <Modal handleClose={onClose} visible={visible} width={480}>
      <div className={styles.top}>
        <div className={styles.title}>Create new APP</div>
        <button className={styles.close} onClick={onClose}>
          Close
        </button>
      </div>
      <Form onFinish={handleSubmit} name="Add API key" form={form}>
        <div className={styles.row}>
          <FormItem name="name" label="API key name">
            <Input name="name" />
          </FormItem>
        </div>
        <div className={styles.row}>
          <FormItem name="blockchain" label="Blockchain">
            <SingleSelect
              options={selectOptions}
              placeholder="Select Blockchain"
              notFoundContent={noDataComponent}
            />
          </FormItem>
        </div>
        <FormItem>
          <Button
            id={CREATE_API_MODAL_BUTTON}
            styleType="filled"
            size="large"
            loading={addKeyLoading || getKeysLoading}
            htmlType="submit">
            Create API key
          </Button>
        </FormItem>
      </Form>
    </Modal>
  );
};

export default memo(ModalCreateApp);
