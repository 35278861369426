import React from 'react';

import { Theme, ThemeProvider } from '@magiclabs/ui';
import '@magiclabs/ui/dist/cjs/index.css';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import googleTagManagerScript from 'utils/googleTagManagerScript';

import App from './app';
import { store } from './store';
import './styles/style.scss';

interface UpdatedThemeProviderProps {
  children: React.ReactElement;
  theme?: Theme;
  root: boolean;
}

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('Failed to find the root element');
}

if (process.env.NODE_ENV === 'production') {
  googleTagManagerScript();
}

const root = createRoot(rootElement);

const TypedThemeProvider = ThemeProvider as React.FC<UpdatedThemeProviderProps>;

if (root) {
  root.render(
    <TypedThemeProvider root>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </TypedThemeProvider>,
  );
}
