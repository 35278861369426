import React from 'react';

import { ReactComponent as CurrencyCrypto } from 'assets/icons/cyrrency-crypto-icon.svg';
import { ReactComponent as CurrencyUsd } from 'assets/icons/cyrrency-usd-icon.svg';

import styles from './PayOverlay.module.scss';

interface PayOverlayProps {
  handleCryptoPay: () => void;
  handleFiatPay: () => void;
}

const PayOverlay: React.FC<PayOverlayProps> = ({ handleCryptoPay, handleFiatPay }) => {
  return (
    <div className={styles.overlay}>
      <button onClick={handleFiatPay}>
        <CurrencyUsd />
        Pay with Card
      </button>
      <button onClick={handleCryptoPay}>
        <CurrencyCrypto />
        Pay with Crypto
      </button>
    </div>
  );
};

export default PayOverlay;
