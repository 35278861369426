import React, { useCallback, useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { useLazyGetAppStatisticQuery } from 'services/statsService';
import {
  useGetApiKeyQuery,
  useGetPaymentsQuery,
  useUpdateApiKeyMutation,
} from 'services/userService';
import { setShowError } from 'slices/notificationSlice';
import { useAppDispatch } from 'store';

import WithSpacing from 'components/with-spacing';
import AppStatisticCard from 'containers/app-statistic-card';

import { getCurrentPlan } from 'utils/payment.utils';

import styles from './App.module.scss';
import AppViewHeader from './app-view-header';
import AppCard from './app_card';

const AppView = () => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const {
    data: payments = [],
    isFetching: paymentsIsLoading,
    error: paymentError,
  } = useGetPaymentsQuery();

  const {
    data: currentKey,
    isFetching: keyIsLoading,
    error: keyError,
  } = useGetApiKeyQuery(params.id as string);

  const [
    getLazyAppStatistic,
    { data: appStatistic, isFetching: appStatisticIsLoading, error: appStatisticError },
  ] = useLazyGetAppStatisticQuery();

  const [updateApiKey, { isLoading: updateApiKeyIsLoading }] = useUpdateApiKeyMutation();

  useEffect(() => {
    if (paymentError || keyError) {
      dispatch(setShowError(true));
    }
  }, [dispatch, paymentError, keyError]);

  useEffect(() => {
    if (currentKey?.api_key) {
      getLazyAppStatistic(currentKey.api_key);
    }
  }, [currentKey?.api_key, getLazyAppStatistic]);

  const STATISTIC_CONFIG = useMemo(
    () => [
      { label: 'Watch Units amount', value: appStatistic?.watchunit_plan?.toLocaleString() },
      { label: 'Watch Units used / month', value: appStatistic?.watchunit_used?.toLocaleString() },
      { label: 'Watch Units / sec', value: appStatistic?.watchunit_per_sec?.toLocaleString() },
      { label: 'Total Requests / month', value: appStatistic?.total_requests?.toLocaleString() },
    ],
    [appStatistic],
  );

  const userCurrentPlan = getCurrentPlan(payments);
  const isLoading = paymentsIsLoading || keyIsLoading || updateApiKeyIsLoading;

  const handleRerunRequest = useCallback(() => {
    if (currentKey?.api_key) {
      getLazyAppStatistic(currentKey.api_key);
    }
  }, [currentKey?.api_key, getLazyAppStatistic]);

  return (
    <div className={styles.wrapper}>
      <AppViewHeader name={currentKey?.name} isLoading={isLoading} />
      <AppCard
        updateApiKey={updateApiKey}
        currentKey={currentKey}
        userCurrentPlan={userCurrentPlan}
        isLoading={isLoading}
        product={userCurrentPlan?.product}
      />
      <WithSpacing spaceSize="extra-large" fullWidth>
        <AppStatisticCard
          isLoading={appStatisticIsLoading || keyIsLoading}
          elements={STATISTIC_CONFIG}
          isError={!!appStatisticError || !!keyError}
          handleRerunRequest={handleRerunRequest}
        />
      </WithSpacing>
      <div>Coming soon...</div>
    </div>
  );
};

export default AppView;
