const googleTagManagerScript = () => {
    const initialScript = document.createElement('script')
    initialScript.src = 'https://www.googletagmanager.com/gtag/js?id=UA-213617868-1'
    document.head.insertBefore(initialScript, document.head.childNodes[0])

    const mainScript = document.createElement('script')
    mainScript.type = 'text/javascript'
    mainScript.textContent = (function ( w, d, s, l, i ) {
        w[l] = w[l] || [];
        w[l].push({
            'gtm.start':
                new Date().getTime(), event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-NF843ZN');
    document.head.insertBefore(mainScript, document.head.childNodes[1])

    const bodyScript = document.createElement('noscript')
    bodyScript.innerHTML = <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NF843ZN"
                                   height="0" width="0" style="display:none;visibility:hidden"></iframe>
    document.body.insertBefore(bodyScript, document.body.childNodes[0])

}

export default googleTagManagerScript