import React from 'react';

import cx from 'classnames';

import Skeleton from 'components/skeleton';

import styles from './SideBar.module.scss';

export const SidebarAppSkeleton = () => {
  return (
    <div className={styles.dropdown}>
      <div className={styles.skeletonAddApp}>
        <Skeleton type="Avatar" style={{ height: 24, width: 24 }} />
        <Skeleton style={{ height: 14, width: 112 }} />
      </div>
      <div className={cx(styles.skeletonApp, styles.bordered)}>
        <Skeleton style={{ height: 14, width: 200 }} />
      </div>
      <div className={styles.skeletonApp}>
        <Skeleton style={{ height: 14, width: 200 }} />
      </div>
    </div>
  );
};
