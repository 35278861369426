import React from 'react';

import WithLabel from 'components/with-label';
import InputWithCopyIcon from 'containers/input-with-copy-icon';

import styles from './KeyLinksOverlay.module.scss';

interface KeyLinksOverlayProps {
  api_key: string;
  url: string;
}

const KeyLinksOverlay: React.FC<KeyLinksOverlayProps> = ({ api_key, url }) => {
  return (
    <div className={styles.dropdown}>
      <div className={styles.row}>
        <WithLabel label="API key">
          <InputWithCopyIcon value={api_key} copy />
        </WithLabel>
      </div>
      <div className={styles.row}>
        <WithLabel label="HTTP link">
          <InputWithCopyIcon value={url} copy />
        </WithLabel>
      </div>
    </div>
  );
};

export default KeyLinksOverlay;
