import React from 'react';

import Button from 'components/button';
import Modal from 'components/modals';
import SectionLabel from 'components/section-label';
import WithSpacing from 'components/with-spacing';

import { ReactComponent as Error } from 'assets/icons/error.svg';

import styles from './ModalDeleteApp.module.scss';

interface ModalDeleteAppProps {
  isDeleteModalOpened: boolean;
  closeModal: () => void;
  cancelChanges: () => void;
  onDelete: () => void;
}

const ModalDeleteApp: React.FC<ModalDeleteAppProps> = ({
  isDeleteModalOpened,
  closeModal,
  cancelChanges,
  onDelete,
}) => {
  return (
    <Modal width={480} visible={isDeleteModalOpened} handleClose={closeModal}>
      <div className={styles.deleteModalWrapper}>
        <WithSpacing spaceSize="extra-medium">
          <Error />
          <SectionLabel>Confirm delete</SectionLabel>
        </WithSpacing>
        <WithSpacing spaceSize="extra-large" className={styles.deleteModalInfo}>
          <span>Are you sure that you want to delete App?</span>
        </WithSpacing>
        <div className={styles.deleteModalButtonsWrapper}>
          <Button styleType="outlinedViolet" handleClick={cancelChanges}>
            Cancel
          </Button>
          <Button styleType="filledError" handleClick={onDelete}>
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDeleteApp;
