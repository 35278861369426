import { useCallback, useEffect, useState } from 'react';

import { SetStateTypes } from 'types/setStateTypes';

const useLocalStorage = <T>(key: string, initValue?: T): [T | undefined, SetStateTypes<T>] => {
  const readValue = useCallback((): T | undefined => {
    if (!window) {
      return initValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? (parseJSON(item) as T) : initValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initValue;
    }
  }, [initValue, key]);

  const setValue = useCallback<SetStateTypes<T>>(
    (newValue) => {
      try {
        const valueToSet = newValue ?? initValue;
        window.localStorage.setItem(key, JSON.stringify(valueToSet));
        setStoredValue(valueToSet as T);
        window.dispatchEvent(new Event('local-storage'));
      } catch {
        console.error(`Can not save ${key} `);
      }
    },
    [initValue, key],
  );

  const [storedValue, setStoredValue] = useState<T | undefined>(readValue);

  const handleStorageChange = useCallback(() => {
    setStoredValue(readValue());
  }, [readValue]);

  useEffect(() => {
    window.addEventListener('local-storage', handleStorageChange);
    return () => {
      window.removeEventListener('local-storage', handleStorageChange);
    };
  }, [handleStorageChange]);

  return [storedValue, setValue];
};

export default useLocalStorage;

const parseJSON = <T>(value: string | null): T | undefined => {
  try {
    if (!value) {
      return undefined;
    } else {
      return JSON.parse(value);
    }
  } catch {
    return undefined;
  }
};
