import initialApiService from 'services/initialService';

import { ProductDetailsType, ProductSubscriptionTypes, ProductType } from 'types/productTypes';

export const productApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getProduct: builder.query<ProductType[], ProductSubscriptionTypes | null>({
      query: (type) => (type ? `product?product_type=${type}` : 'product'),
    }),
    getProductDetails: builder.query<ProductDetailsType[], void>({
      query: () => 'product/details',
    }),
  }),
});

export const { useGetProductQuery, useGetProductDetailsQuery, useLazyGetProductQuery } =
  productApiService;
