import initialApiService from 'services/initialService';

import { AppStatistic, OverallStatistic } from 'types/statisticTypes';

export const statsApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getOverallStatistic: builder.query<OverallStatistic, void>({
      query: () => 'stats/overall',
    }),
    getAppStatistic: builder.query<AppStatistic, string>({
      query: (apiKey) => `stats/app?api_key=${apiKey}`,
    }),
  }),
});

export const { useGetOverallStatisticQuery, useLazyGetAppStatisticQuery } = statsApiService;
