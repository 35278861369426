import React from 'react';

import { ProductDetailsValue } from 'types/productTypes';

import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';

import { MainFeaturesTableHeaderSkeleton } from './mainFeatures.skeleton';

export const DEFAULT_FIRST_COLUMN = {
  label: 'Full Feature List',
};

export const loadingArray = Array.from({ length: 4 }, (_, i) => i);

export const renderTableSkeletonHeader = (e: { label?: string } | number) => {
  if (typeof e !== 'number') {
    return {
      children: e.label,
    };
  }

  return {
    children: <MainFeaturesTableHeaderSkeleton />,
  };
};
export const getCellContent = (
  val: ProductDetailsValue,
): string | number | React.ReactElement | null => {
  const { text } = val;

  switch (val.type) {
    case 'TEXT':
      if (isNaN(Number(text))) {
        return text;
      }

      if (!text) {
        return ' - ';
      }

      return Number(text).toLocaleString();
    case 'FLAG':
      return val.flag ? <SuccessIcon /> : ' - ';
    default:
      return null;
  }
};

export const handleEnterpriseTextValue = (val: string | number) => {
  if (!isNaN(Number(val))) {
    return Number(val) || '-';
  }

  return val;
};
