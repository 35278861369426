import React from 'react';

import { setOpenContactUsModal } from 'slices/contactUsModalSlice';
import { useAppDispatch } from 'store';

import Button from 'components/button';
import SectionLabel from 'components/section-label';
import WithSpacing from 'components/with-spacing';

import { ProductType } from 'types/productTypes';

import styles from './EnterpriseFooter.module.scss';

interface EnterpriseFooterProps {
  product?: ProductType;
}

const EnterpriseFooter: React.FC<EnterpriseFooterProps> = ({ product }) => {
  const dispatch = useAppDispatch();

  if (!product) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.enterprise}>
        <div className={styles.round}>
          <img src={product.logo} alt="rocket icon" />
        </div>
        <div>
          <WithSpacing placement="left" spaceSize="xextra-large">
            <WithSpacing placement="bottom" spaceSize="extra-large">
              <SectionLabel>{product.name}</SectionLabel>
            </WithSpacing>
          </WithSpacing>
          <WithSpacing placement="left" spaceSize="xextra-large">
            <div>{product.description}</div>
          </WithSpacing>
        </div>
      </div>
      <Button
        size="large"
        styleType="outlinedViolet"
        fullWidth={false}
        handleClick={() => {
          dispatch(
            setOpenContactUsModal({
              modalType: 'BUY_ENTERPRISE',
            }),
          );
        }}>
        Contact us
      </Button>
    </div>
  );
};

export default EnterpriseFooter;
