import initialApiService from 'services/initialService';

import { ContactUsType } from 'types/contactUsTypes';

const contactUsApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    contactUs: builder.query({
      query: (params: ContactUsType) => ({
        url: 'contact_us',
        method: 'POST',
        body: params,
      }),
    }),
  }),
});

export const { useLazyContactUsQuery } = contactUsApiService;
