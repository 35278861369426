import React, { useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useGetBlockChainListQuery } from 'services/infoService';

import Blockchain from 'components/blockchain';
import Button from 'components/button/button';
import Dropdown from 'components/dropdown';
import WithSkeletonLoader from 'components/with-skeleton-loader';
import ApiKeyRowSkeleton from 'containers/api-key-row/apiKeyRow.skeleton';
import KeyLinksOverlay from 'containers/key-links-overlay';

import {
  HOME_VIEW_API_KEY_BUTTON,
  HOME_VIEW_API_KEY_DETAILS_BUTTON,
} from 'constants/productFruitsTags';
import { getAppWithIdRoute } from 'constants/routes';
import { ApiKeyType } from 'types/apiKeyTypes';
import { BlockchainType } from 'types/blockchainTypes';

import styles from './ApiKeyRow.module.scss';

interface ApiKeyRowProps {
  item: ApiKeyType | number;
  index?: number;
  isLoading?: boolean;
}

const ApiKeyRow: React.FC<ApiKeyRowProps> = ({ item, isLoading, index }): React.ReactElement => {
  const navigate = useNavigate();
  const [isCopy, setIsCopy] = useState<boolean>(false);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const { data: options = [] } = useGetBlockChainListQuery();

  const nonLoadingItem = item as ApiKeyType;
  const blockchains = useMemo(() => {
    return options?.reduce(
      (acc: { [key: string]: Partial<BlockchainType> }, curr: BlockchainType) => (
        (acc[curr.symbol] = { icon: curr.icon_url, label: curr.name }), acc
      ),
      {},
    );
  }, [options]);

  useEffect(() => {
    let interval: NodeJS.Timer | number | undefined = undefined;
    if (isCopy) {
      interval = setInterval(() => {
        setIsCopy(false);
      }, 3000);
    }
    return () => clearInterval(interval as number);
  }, [isCopy]);

  return (
    <tr className={styles.wrapper}>
      <WithSkeletonLoader isLoading={!!isLoading} skeletonLoader={<ApiKeyRowSkeleton />}>
        <td align={'left'}>
          <div className={styles.overflowed}>{nonLoadingItem.name}</div>
        </td>
        <td align={'left'}>
          <Blockchain blockchain={blockchains[nonLoadingItem.blockchain] as BlockchainType} />
        </td>
        <td align={'left'}>
          <Dropdown
            getPopupContainer={(e) => {
              return e.parentElement?.parentElement as HTMLElement;
            }}
            onVisibleChange={setDropdownVisible}
            visible={dropdownVisible}
            overlay={
              <KeyLinksOverlay
                api_key={nonLoadingItem?.api_key || ''}
                url={nonLoadingItem?.url || ''}
              />
            }>
            <Button
              dataId={`${HOME_VIEW_API_KEY_BUTTON}-${index}`}
              fullWidth={false}
              className={styles.viewKey}
              size="medium"
              styleType="outlinedViolet"
              handleClick={() => setDropdownVisible(!dropdownVisible)}>
              View my Key
            </Button>
          </Dropdown>
        </td>
        <td align={'center'}>
          <Button
            dataId={`${HOME_VIEW_API_KEY_DETAILS_BUTTON}-${index}`}
            fullWidth={false}
            className={styles.viewKey}
            size="medium"
            styleType="outlinedGray"
            handleClick={() => {
              if (typeof item !== 'number') {
                navigate(getAppWithIdRoute(item.id.toString()));
              }
            }}>
            View details
          </Button>
        </td>
      </WithSkeletonLoader>
    </tr>
  );
};

export default ApiKeyRow;
