import React, { createContext, memo, useCallback, useMemo, useState } from 'react';

import { useLazyGetPortalQuery } from 'services/paymentService';
import { useLazyGetProductQuery } from 'services/productService';
import { useGetPaymentsQuery, useLazyGetPaymentsQuery } from 'services/userService';
import { setShowError, showDefaultNotification } from 'slices/notificationSlice';
import { useAppDispatch } from 'store';

import Button from 'components/button/button';
import ModalCancelSubscription from 'containers/modal-cancel-subscription';

import { COMPARE_PLANS_ROUTE } from 'constants/routes';
import { PaymentType } from 'types/paymentTypes';
import { ProductType } from 'types/productTypes';

import { ReactComponent as NoDataIcon } from 'assets/icons/no-data-icon.svg';

import { getCurrentPlan, userPlanHasNoRenewalOrExpired } from 'utils/payment.utils';

import styles from './SimpleCurrentSubscription.module.scss';
import SimpleCurrentSubscriptionCard from './simpleCurrentSubscriptionCard';

export type SimpleCurrentSubscriptionCardContextType = {
  userCurrentPlan: PaymentType;
  product: ProductType;
  handleEditCard: () => void;
  isLoading: boolean;
  handleRenewSubscription: (id: string | number) => void;
  openCancelSubscriptionModal: () => void;
};

export const SimpleCurrentSubscriptionCardContext = createContext<
  SimpleCurrentSubscriptionCardContextType | undefined
>(undefined);

const SUCCESS_RESUBSCRIBE = {
  text: 'Your subscription successfully renewed.',
  notificationType: 'success',
};

interface SimpleCurrentSubscriptionProps {
  reactivateSession: (id: string | number) => Promise<{ [key: string]: unknown }>;
  isLoading: boolean;
}

const SimpleCurrentSubscription: React.FC<SimpleCurrentSubscriptionProps> = ({
  isLoading: reactivateIsLoading,
  reactivateSession,
}) => {
  const BASE_URL = `${window.location.origin}${COMPARE_PLANS_ROUTE}`;

  const dispatch = useAppDispatch();

  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false);

  const [getProduct, { isFetching: productsIsFetching }] = useLazyGetProductQuery();
  const [getPayments, { isFetching: paymentsIsFetching, isError: paymentsError }] =
    useLazyGetPaymentsQuery();
  const [getPortal, { isLoading: isPortalLoading }] = useLazyGetPortalQuery();
  const { data: payments = [] } = useGetPaymentsQuery();

  const openCancelSubscriptionModal = useCallback(() => setShowCancelSubscriptionModal(true), []);
  const closeCancelSubscriptionModal = () => setShowCancelSubscriptionModal(false);

  const userCurrentPlan = getCurrentPlan(payments);

  const product = userCurrentPlan?.product;

  const handleEditCard = useCallback(() => {
    getPortal({ return_url: BASE_URL }).then((res) => {
      if ('data' in res) {
        window.open(res.data, '_blank');
      }
    });
  }, [BASE_URL, getPortal]);

  const handleRenewSubscription = useCallback(
    (id: string | number) => {
      reactivateSession(id).then((res) => {
        if ('error' in res) {
          dispatch(setShowError(true));
        } else {
          getPayments();
          getProduct('SUBSCRIPTION');
          dispatch(showDefaultNotification(SUCCESS_RESUBSCRIBE));
        }
      });
    },
    [dispatch, getPayments, getProduct, reactivateSession],
  );

  const handleRerunRequest = useCallback(() => {
    getPayments();
  }, [getPayments]);

  const errorContent = useMemo(
    () => (
      <div className={styles.errorWrapper}>
        <NoDataIcon />
        <div>
          <div>It looks like the payments didn’t load correctly.</div>
          <div>Please refresh or try again later.</div>
        </div>
        <Button
          handleClick={handleRerunRequest}
          fullWidth={false}
          size="medium"
          styleType="outlinedViolet">
          Refresh
        </Button>
      </div>
    ),
    [handleRerunRequest],
  );

  const isLoading =
    reactivateIsLoading || productsIsFetching || paymentsIsFetching || isPortalLoading;

  const memoizedContext = useMemo(
    () => ({
      userCurrentPlan: userCurrentPlan,
      product,
      isLoading,
      handleRenewSubscription,
      handleEditCard,
      openCancelSubscriptionModal,
    }),
    [
      handleEditCard,
      handleRenewSubscription,
      openCancelSubscriptionModal,
      product,
      isLoading,
      userCurrentPlan,
    ],
  );

  if (product === undefined) {
    return null;
  }

  const card = userPlanHasNoRenewalOrExpired(userCurrentPlan) ? (
    <SimpleCurrentSubscriptionCard>
      <SimpleCurrentSubscriptionCard.ExpiredInfo />
      <SimpleCurrentSubscriptionCard.ExpiredActionButtons />
    </SimpleCurrentSubscriptionCard>
  ) : (
    <SimpleCurrentSubscriptionCard>
      <SimpleCurrentSubscriptionCard.Info />
      <SimpleCurrentSubscriptionCard.ActionButtons />
    </SimpleCurrentSubscriptionCard>
  );

  return (
    <>
      <ModalCancelSubscription
        onClose={closeCancelSubscriptionModal}
        visible={showCancelSubscriptionModal}
        id={userCurrentPlan?.id || ''}
        endDate={userCurrentPlan?.end_date || ''}
      />
      {!isLoading && paymentsError ? (
        errorContent
      ) : (
        <SimpleCurrentSubscriptionCardContext.Provider
          value={memoizedContext as SimpleCurrentSubscriptionCardContextType}>
          {card}
        </SimpleCurrentSubscriptionCardContext.Provider>
      )}
    </>
  );
};

export default memo(SimpleCurrentSubscription);
