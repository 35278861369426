import React from 'react';

import Icon from 'components/icon';

import { Children } from 'types/childrenTypes';

import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import logo from 'assets/icons/logo.svg';
import logoWhite from 'assets/icons/logo_white.svg';
import notifications from 'assets/icons/notifications.svg';
import rawData from 'assets/icons/raw-data.svg';
import structuredData from 'assets/icons/structured-data.svg';

import styles from './LoginLayout.module.scss';

interface LoginLayoutProps {
  children: Children;
}

const LoginLayout: React.FC<LoginLayoutProps> = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <div className={styles.content}>
          <img
            src={logoWhite}
            alt="WatchData logo"
            width="146"
            height="24"
            className={styles.logo}
          />
          <a href="https://www.watchdata.io/" className={styles.back}>
            <ArrowLeft />
          </a>
          <h2 className={styles.title}>
            Your infrastructure <br />
            for building
            <br />
            blockchain apps
          </h2>
          <ul className={styles.features}>
            <li>
              <Icon src={rawData} alt="raw data icon" size="extraLarge" />
              <span>Raw data</span>
            </li>
            <li>
              <Icon src={structuredData} alt="structured data icon" size="extraLarge" />
              <span>Structured data</span>
            </li>
            <li>
              <Icon src={notifications} alt="notification icon" size="extraLarge" />
              <span>Notifications</span>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.right}>
        <img src={logo} alt="WatchData logo" width="146" height="24" className={styles.logo} />
        <div className={styles.center}>
          <a href="https://www.watchdata.io/" className={styles.back}>
            <ArrowLeft />
          </a>
          {children}
        </div>
        <div className={styles.terms}>
          By registering, I agree to the{' '}
          <a href="https://www.watchdata.io/terms-of-use" target="_blank">
            Terms of Use
          </a>{' '}
          of TheWatch and accept TheWatch’s{' '}
          <a href="https://www.watchdata.io/privacy-policy" target="_blank">
            Privacy Policy
          </a>
          .
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
