import React, { useCallback, useState } from 'react';

import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetBlockChainListQuery } from 'services/infoService';
import {
  useDeleteApiKeyMutation,
  useLazyGetApiKeyQuery,
  useLazyGetApiKeysQuery,
} from 'services/userService';
import { setShowError } from 'slices/notificationSlice';
import { useAppDispatch } from 'store';

import Button from 'components/button';
import Dropdown from 'components/dropdown';
import WithSkeletonLoader from 'components/with-skeleton-loader';
import WithSpacing from 'components/with-spacing';
import KeyLinksOverlay from 'containers/key-links-overlay';
import PlanElement from 'containers/plan-element';

import { HOME_ROUTE } from 'constants/routes';
import { ApiKeyType } from 'types/apiKeyTypes';
import { BlockchainType } from 'types/blockchainTypes';
import { PaymentType } from 'types/paymentTypes';
import { ProductType } from 'types/productTypes';

import cardBackground from 'assets/background/slim-card-vector-backround-icon.svg';

import styles from './AppCard.module.scss';
import { AppActionButtonsSkeleton } from './appCard.skeleton';
import ModalConfigApp from './modal-config-app';
import ModalDeleteApp from './modal-delete-app';

interface AppCardProps {
  product?: ProductType;
  currentKey?: ApiKeyType;
  userCurrentPlan?: PaymentType;
  isLoading: boolean;
  updateApiKey: ({
    name,
    id,
    blockchain,
  }: {
    name: string;
    id: string | number;
    blockchain: string;
  }) => Promise<unknown>;
}

const AppCard: React.FC<AppCardProps> = ({
  userCurrentPlan,
  isLoading,
  currentKey,
  updateApiKey,
}): React.ReactElement => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [isAppModalOpened, setIsAppModalOpened] = useState<boolean>(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState<boolean>(false);

  const [deleteApiKey] = useDeleteApiKeyMutation();
  const [getApiKeys, { isFetching: getApiKeysIsLoading }] = useLazyGetApiKeysQuery();
  const [getApiKey, { isFetching: getApiKeyIsLoading }] = useLazyGetApiKeyQuery();
  const { data: blockchains = [] } = useGetBlockChainListQuery();

  const currentBlockchain = blockchains.find(
    (blockchain: BlockchainType) => blockchain.symbol === currentKey?.blockchain,
  ) as BlockchainType;

  const openDeleteModal = useCallback((): void => {
    setIsAppModalOpened(false);
    setIsDeleteModalOpened(true);
  }, []);

  const onDelete = useCallback((): void => {
    deleteApiKey(params.id as string).then((e) => {
      if ((e as { error: FetchBaseQueryError | SerializedError }).error) {
        return dispatch(setShowError(true));
      }
      setIsDeleteModalOpened(false);
      navigate(HOME_ROUTE);
      getApiKeys();
    });
  }, [getApiKeys, deleteApiKey, dispatch, navigate, params.id]);

  const saveChanges = useCallback(
    (name: string): void => {
      if (currentKey) {
        updateApiKey({
          name,
          id: currentKey.id,
          blockchain: currentKey.blockchain,
        }).then(() => {
          getApiKeys();
          getApiKey(params.id as string);
        });
      }
      setIsAppModalOpened(false);
    },
    [currentKey, getApiKey, getApiKeys, params.id, updateApiKey],
  );

  const cancelChanges = useCallback((): void => {
    setIsAppModalOpened(false);
    setIsDeleteModalOpened(false);
  }, []);

  const combinedLoading = isLoading || getApiKeysIsLoading || getApiKeyIsLoading;

  return (
    <>
      <ModalDeleteApp
        isDeleteModalOpened={isDeleteModalOpened}
        closeModal={() => setIsDeleteModalOpened(false)}
        cancelChanges={cancelChanges}
        onDelete={onDelete}
      />
      {currentKey?.name && (
        <ModalConfigApp
          isAppModalOpened={isAppModalOpened}
          closeModal={() => setIsAppModalOpened(false)}
          product={userCurrentPlan?.product}
          currentKey={currentKey}
          openDeleteModal={openDeleteModal}
          saveChanges={saveChanges}
          cancelChanges={cancelChanges}
        />
      )}
      <WithSpacing className={styles.wrapper} spaceSize="large" fullWidth={true}>
        <img src={cardBackground} alt="background" className={styles.background} />
        <div className={styles.contentWrapper}>
          <div className={styles.planInfo}>
            <PlanElement
              isLoading={combinedLoading}
              sectionLabel="App name"
              info={currentKey?.name || ''}
            />
            <PlanElement
              isLoading={combinedLoading}
              sectionLabel="Network"
              info={currentBlockchain?.name || ''}
              elementIcon={<img src={currentBlockchain?.icon_url} alt="currency icon" />}
              showBorder={false}
            />
          </div>
          <WithSkeletonLoader
            isLoading={combinedLoading}
            skeletonLoader={<AppActionButtonsSkeleton />}>
            <div className={styles.actionGroup}>
              <Dropdown
                getPopupContainer={(e) => {
                  return e.parentElement?.parentElement as HTMLElement;
                }}
                placement="bottomRight"
                onVisibleChange={setDropdownVisible}
                visible={dropdownVisible}
                overlay={
                  <KeyLinksOverlay
                    api_key={currentKey?.api_key || ''}
                    url={currentKey?.url || ''}
                  />
                }>
                <Button
                  size="medium"
                  styleType="outlinedGray"
                  theme="black"
                  fullWidth={false}
                  handleClick={() => setDropdownVisible(true)}>
                  View API key
                </Button>
              </Dropdown>
              <Button
                size="medium"
                styleType="outlinedGray"
                theme="black"
                fullWidth={false}
                handleClick={() => setIsAppModalOpened(true)}>
                Edit App
              </Button>
            </div>
          </WithSkeletonLoader>
        </div>
      </WithSpacing>
    </>
  );
};

export default AppCard;
