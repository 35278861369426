import React from 'react';

import styles from './SectionLabel.module.scss';

interface SectionLabelProps {
  children: string;
}

const SectionLabel: React.FC<SectionLabelProps> = ({ children }) => {
  return <h2 className={styles.heading}>{children}</h2>;
};

export default SectionLabel;
