import React from 'react';

import cx from 'classnames';

import TablePagination from 'components/table/table-pagination/tablePagination';

import { Align } from 'types/baseTypes';
import { Children } from 'types/childrenTypes';

import styles from './Table.module.scss';

interface TableProps<R> {
  columns: {
    children?: Children;
    label?: string;
    align?: Align;
    className?: string;
  }[];
  items: R[];
  renderItem: (item: R, index: number) => React.ReactElement;
  className?: string;
  isError?: boolean;
  isLoading?: boolean;
  noDataComponent?: React.ReactElement;
  errorComponent?: React.ReactElement;

  // PAGINATION
  showPagination?: boolean;
  totalCount?: number | string;
  offset?: number | string;
  limit?: number | string;
  handlePaginationChange?: (page: number) => void;
}

const Table = <R extends object | number>({
  noDataComponent,
  errorComponent,
  columns,
  items,
  renderItem,
  className = '',
  showPagination = true,
  isError,
  isLoading,
  totalCount,
  limit,
  offset,
  handlePaginationChange,
}: TableProps<R>): React.ReactElement => {
  let tableBodyContent = <>{items?.map(renderItem)}</>;

  const noDataOrError = !items?.length || isError;

  if (!isLoading && noDataOrError) {
    if (isError) {
      tableBodyContent = errorComponent || <></>;
    } else {
      tableBodyContent = noDataComponent || <></>;
    }
  }
  return (
    <div className={styles.wrapper}>
      <table className={cx({ [className]: className })}>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index} align={column.align || 'center'} className={column.className}>
                {column.label}
                {column.children}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{tableBodyContent}</tbody>
      </table>
      <TablePagination
        showPagination={showPagination}
        handlePaginationChange={handlePaginationChange}
        totalCount={noDataOrError ? 0 : totalCount}
        offset={offset}
        limit={limit}
      />
    </div>
  );
};

export default Table;
