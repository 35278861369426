import { SignInResponseType, VerifyEmailPropsType } from 'services/auth/authService.types';
import initialApiService from 'store/services/initialService';

import { browserDetect } from 'utils/browserDetect';

import { createAuthUrl } from './utils';

const BROWSER = browserDetect();

const authApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    signOut: builder.mutation<void, void>({
      query: () => ({
        url: createAuthUrl('sign_out'),
        method: 'POST',
      }),
    }),
    signIn: builder.query<SignInResponseType, string>({
      query: (sign_in_secret) => ({
        url: createAuthUrl('sign_in'),
        method: 'POST',
        body: {
          sign_in_secret,
        },
      }),
    }),
    verifyEmail: builder.query<void, VerifyEmailPropsType>({
      query: (body) => ({
        url: createAuthUrl('verify_email'),
        method: 'POST',
        body: {
          ...body,
          user_metadata: {
            country: '',
            region: '',
            browser: BROWSER,
          },
        },
      }),
    }),
  }),
});

export const signInEndpoint = authApiService.endpoints.signIn;

export const { useSignOutMutation, useLazyVerifyEmailQuery, useLazySignInQuery } = authApiService;
