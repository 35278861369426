// STYLES
import React from 'react';

import cx from 'classnames';

// TYPES
import { Children } from 'types/childrenTypes';
import { PlacementType, SpacingType } from 'types/withSpacingTypes';

import styles from './WithSpacing.module.scss';

interface WithSpacingProps {
  children: Children;
  spaceSize?: SpacingType;
  placement?: PlacementType;
  className?: string;
  fullWidth?: boolean;
}

const WithSpacing: React.FC<WithSpacingProps> = ({
  children,
  spaceSize = 'medium',
  placement = 'bottom',
  className: classNameFromProps = '',
  fullWidth = false,
}) => {
  const className = `${placement}__${spaceSize}` as never;

  return (
    <div
      className={cx({
        [styles[className]]: true,
        [styles.wrapper]: true,
        [classNameFromProps]: classNameFromProps,
        [styles.fullWidth]: fullWidth,
      })}>
      {children}
    </div>
  );
};

export default WithSpacing;
