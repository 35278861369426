import React, { memo } from 'react';

import styles from '../Table.module.scss';

import Pagination from 'components/pagination';

import { getCurrentPage } from 'utils/pagination';

interface TablePaginationProps {
  showPagination?: boolean;
  totalCount?: number | string;
  offset?: number | string;
  limit?: number | string;
  handlePaginationChange?: (page: number) => void;
}

const TablePagination: React.FC<TablePaginationProps> = ({
  showPagination,
  totalCount,
  offset,
  limit,
  handlePaginationChange,
}) => {
  if (!showPagination || typeof totalCount !== 'number') {
    return <></>;
  }

  return (
    <div className={styles.paginationWrapper}>
      <Pagination
        current={getCurrentPage(offset, limit)}
        total={Number(totalCount)}
        onChange={handlePaginationChange}
        pageSize={Number(limit)}
      />
    </div>
  );
};

export default memo(TablePagination);
