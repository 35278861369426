import React, { useCallback, useState } from 'react';

import { useLazyGetProductQuery } from 'services/productService';
import { useGetPaymentsQuery, useLazyGetPaymentsQuery } from 'services/userService';
import { setOpenContactUsModal } from 'slices/contactUsModalSlice';
import { setShowError, showDefaultNotification } from 'slices/notificationSlice';
import { useAppDispatch } from 'store';

import Button from 'components/button';
import Dropdown from 'components/dropdown';
import PayOverlay from 'containers/pay-overlay';

import { BUTTON_LABEL } from 'constants/productButton';
import { PRICING_ROUTE } from 'constants/routes';
import { getSessionParamsType } from 'types/stripeTypes';

import { getCurrentPlan, userPlanHasNoRenewalOrExpired } from 'utils/payment.utils';

interface ProductActionButtonProps {
  is_can_buy: boolean | null;
  id: string | number;
  getSession: (
    params: getSessionParamsType,
  ) => Promise<{ data?: { checkout_session_url: string | URL | undefined } }>;
  reactivateSession: (id: string | number) => Promise<{ [key: string]: unknown }>;
}

const SUCCESS_RESUBSCRIBE = {
  text: 'Your subscription successfully renewed.',
  notificationType: 'success',
};

const ProductActionButton: React.FC<ProductActionButtonProps> = ({
  is_can_buy,
  id,
  getSession,
  reactivateSession,
}) => {
  const dispatch = useAppDispatch();
  const [isPayOverlayOpen, setIsPayOverlayOpen] = useState<boolean>(false);

  const { data: payments = [] } = useGetPaymentsQuery();

  const [getProduct] = useLazyGetProductQuery();
  const [getPayments] = useLazyGetPaymentsQuery();

  const userCurrentPlan = getCurrentPlan(payments);
  const isPlanExpired = userPlanHasNoRenewalOrExpired(userCurrentPlan);

  const handleOverlayOpening = () => setIsPayOverlayOpen((prevState) => !prevState);

  const handleOpenModal = () => {
    dispatch(
      setOpenContactUsModal({
        modalType: 'BUY_ENTERPRISE',
      }),
    );
  };

  const handleRenewSubscription = useCallback(() => {
    reactivateSession(userCurrentPlan?.id!).then((res) => {
      if ('error' in res) {
        dispatch(setShowError(true));
      } else {
        getPayments();
        getProduct('SUBSCRIPTION');
        dispatch(showDefaultNotification(SUCCESS_RESUBSCRIBE));
      }
    });
  }, [dispatch, getPayments, getProduct, reactivateSession, userCurrentPlan?.id]);

  const handleCryptoPay = useCallback(() => {
    setIsPayOverlayOpen(false);
    dispatch(
      setOpenContactUsModal({
        modalType: 'BUY_WITH_CRYPTO',
        productId: id,
      }),
    );
  }, [dispatch, id]);

  const handleFiatPay = useCallback(() => {
    setIsPayOverlayOpen(false);
    // safari blocks window.open in async actions that's why we use workaround
    const windowOpened = window.open();

    const BASE_URL = `${window.location.origin}${PRICING_ROUTE}`;
    getSession({
      success_url: `${BASE_URL}?subscription=success`,
      cancel_url: `${BASE_URL}?subscription=fail`,
      subscribe_model: {
        product_id: id,
      },
    })
      .then((res) => {
        if (res?.data?.checkout_session_url) {
          windowOpened!.location = res?.data?.checkout_session_url as string;
        }
      })
      .catch(() => {
        dispatch(setShowError(true));
      });
  }, [dispatch, getSession, id]);

  if (is_can_buy === null) {
    return (
      <Button
        fullWidth={false}
        size="medium"
        disabled
        styleType="outlinedViolet"
        handleClick={handleOverlayOpening}>
        {BUTTON_LABEL.DEFAULT}
      </Button>
    );
  }

  if (is_can_buy) {
    const isCurrentPlan = userCurrentPlan?.product?.id === id;
    if (isCurrentPlan && isPlanExpired) {
      return (
        <Button
          size="medium"
          styleType="outlinedViolet"
          fullWidth={false}
          handleClick={handleRenewSubscription}>
          {BUTTON_LABEL.RENEW_SUBSCRIPTION}
        </Button>
      );
    }

    return (
      <Dropdown
        overlay={<PayOverlay handleCryptoPay={handleCryptoPay} handleFiatPay={handleFiatPay} />}
        disabled={userCurrentPlan?.product?.id === id}
        getPopupContainer={(e) => e}
        placement="bottomLeft"
        onVisibleChange={() => setIsPayOverlayOpen(false)}
        visible={isPayOverlayOpen}>
        <Button
          size="medium"
          styleType="outlinedViolet"
          fullWidth={false}
          handleClick={handleOverlayOpening}>
          {BUTTON_LABEL.GET_STARTED}
        </Button>
      </Dropdown>
    );
  } else {
    return (
      <Button
        size="medium"
        styleType="outlinedViolet"
        fullWidth={false}
        handleClick={handleOpenModal}>
        {BUTTON_LABEL.CONTACT_US}
      </Button>
    );
  }
};

export default ProductActionButton;
