import React, { FC, PropsWithChildren } from 'react';

import { Form as FormAntd, FormItemProps as FormItemPropsAntd } from 'antd';

export type FormItemProps = PropsWithChildren<FormItemPropsAntd>;

const FormItem: FC<FormItemProps> = (props) => {
  const { className = '', ...otherProps } = props;
  return <FormAntd.Item className={className} {...otherProps} />;
};

export default FormItem;
