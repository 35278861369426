import React, { useEffect } from 'react';

import { useDeactivateSessionMutation } from 'services/paymentService';
import { useLazyGetPaymentsQuery } from 'services/userService';
import { setShowError, showDefaultNotification } from 'slices/notificationSlice';
import { useAppDispatch } from 'store';

import Button from 'components/button/button';
import Modal from 'components/modals';
import WithSpacing from 'components/with-spacing';

import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';

import { convertDateToLocale } from 'utils/convertStringDateToLocale';

import styles from './ModalCancelSubscription.module.scss';

interface ModalCancelSubscriptionProps {
  onClose: () => void;
  visible: boolean;
  id: string | number;
  endDate: string;
}

const SUCCESS_UNSUBSCRIBE = {
  text: 'Your subscription successfully cancelled',
  notificationType: 'success',
};
const FAILED_UNSUBSCRIBE = {
  text: 'Your subscription cancellation failed',
  notificationType: 'fail',
};

const ModalCancelSubscription: React.FC<ModalCancelSubscriptionProps> = ({
  onClose,
  visible,
  id,
  endDate,
}) => {
  const dispatch = useAppDispatch();
  const [deactivateSession, { isLoading }] = useDeactivateSessionMutation();
  const [getPayments, { isFetching: paymentsIsLoading, error: paymentsError }] =
    useLazyGetPaymentsQuery();

  useEffect(() => {
    if (paymentsError) {
      dispatch(setShowError(true));
    }
  }, [dispatch, paymentsError]);

  const cancelSubscription = () => {
    deactivateSession(id).then((res) => {
      if ('error' in res) {
        dispatch(showDefaultNotification(FAILED_UNSUBSCRIBE));
      } else {
        dispatch(showDefaultNotification(SUCCESS_UNSUBSCRIBE));
        getPayments();
        onClose();
      }
    });
  };

  const formattedEndDate = convertDateToLocale(endDate, 'short');

  return (
    <Modal handleClose={onClose} visible={visible} width={480}>
      <WithSpacing fullWidth className={styles.header} spaceSize="extra-medium">
        <ErrorIcon />
        <header>Cancel subscription</header>
      </WithSpacing>
      <WithSpacing spaceSize="extra-large" className={styles.message}>
        <span>Are you sure that you want to cancel your subscription?</span>
        <span>{`Your plan will be end: ${formattedEndDate}.`}</span>
      </WithSpacing>
      <div className={styles.actionButtonsGroup}>
        <Button handleClick={onClose} styleType="outlinedViolet">
          Close
        </Button>
        <Button
          handleClick={cancelSubscription}
          styleType="filledError"
          loading={isLoading || paymentsIsLoading}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default ModalCancelSubscription;
