import React, { memo, useCallback, useMemo } from 'react';

import cx from 'classnames';

import { useLazyGetSessionQuery } from 'services/paymentService';
import { useGetProductDetailsQuery, useGetProductQuery } from 'services/productService';
import { useGetPaymentsQuery } from 'services/userService';

import Button from 'components/button/button';
import Table from 'components/table';
import Tooltip from 'components/tooltip';
import WithLoader from 'components/with-loader';
import WithSpacing from 'components/with-spacing';

import { CURRENCY } from 'constants/currency';
import { ProductDetailsType, ProductDetailsValue, ProductType } from 'types/productTypes';

import { ReactComponent as InfoTooltipIcon } from 'assets/icons/info-tooltip-icon.svg';
import { ReactComponent as NoDataIcon } from 'assets/icons/no-data-icon.svg';

import styles from './MainFeatures.module.scss';
import { MainFeaturesTableContentSkeleton } from './mainFeatures.skeleton';
import {
  DEFAULT_FIRST_COLUMN,
  getCellContent,
  handleEnterpriseTextValue,
  loadingArray,
  renderTableSkeletonHeader,
} from './mainFeatures.utils';
import ProductActionButton from './product-action-button';

const renderCellContent = (val: ProductDetailsValue) => (
  <td key={`${val.id}${val.product_id}`} align="center">
    {getCellContent(val)}
  </td>
);

const enterpriseValuesModification = (val: ProductDetailsValue, id: string | number) => {
  return val.product_id === id && val.type === 'TEXT'
    ? { ...val, text: handleEnterpriseTextValue(val.text) }
    : val;
};

const renderTableCells = (e: ProductDetailsType, index: number) => (
  <tr className={cx({ [styles.rowColored]: index % 2 })} key={e.label.text}>
    <td align="left">
      <div className={styles.rowStart}>
        {e.label.text}
        {!!e.label.tooltip_text && (
          <WithSpacing placement="left">
            <Tooltip title={e.label.tooltip_text}>
              <InfoTooltipIcon />
            </Tooltip>
          </WithSpacing>
        )}
      </div>
    </td>
    {e.values.map(renderCellContent)}
  </tr>
);

interface MainFeaturesProps {
  reactivateSession: (id: string | number) => Promise<{ [key: string]: unknown }>;
  isLoading: boolean;
}

const MainFeatures: React.FC<MainFeaturesProps> = ({
  reactivateSession,
  isLoading: reactivateSessionIsLoading,
}) => {
  const {
    data: productDetails,
    isFetching: productDetailsIsLoading,
    isError: productDetailsError,
    refetch: productsDetailsRefetch,
  } = useGetProductDetailsQuery();
  const {
    data: products,
    isFetching: productsIsLoading,
    isError: productsError,
    refetch: productsRefetch,
  } = useGetProductQuery('SUBSCRIPTION');
  const { isLoading: paymentIsLoading, isError: paymentError } = useGetPaymentsQuery();

  const [getSession, { isFetching: stripeIsLoading }] = useLazyGetSessionQuery();

  const tableDataIsLoading =
    productDetailsIsLoading || productsIsLoading || paymentIsLoading || reactivateSessionIsLoading;
  const tableError = productDetailsError || productsError || paymentError;

  const priceWithWrapper = useCallback(
    ({
      is_can_buy,
      currency,
      current_price,
    }: Partial<
      Pick<ProductType, 'current_price' | 'currency' | 'is_can_buy'>
    >): React.ReactElement => {
      let price = '';
      if (is_can_buy === null) {
        price = '$0';
      }
      if (is_can_buy) {
        price = `${CURRENCY[currency as string]} ${current_price}`;
      }
      return <WithSpacing className={styles.price}>{price}</WithSpacing>;
    },
    [],
  );

  const renderTableHeader = (product: ProductType | { label: string }) => {
    if ('label' in product) {
      return {
        children: product.label,
      };
    }

    return {
      children: (
        <div
          key={product.id}
          className={cx({
            [styles.headerElement]: true,
            // should color popular plan
            [styles.headerElementColored]: product.theme === 'Business',
          })}>
          <WithSpacing>
            <header>{product.name}</header>
          </WithSpacing>
          {priceWithWrapper({
            is_can_buy: product.is_can_buy,
            currency: product.currency,
            current_price: product.current_price,
          })}
          <ProductActionButton
            id={product.id}
            is_can_buy={product.is_can_buy}
            getSession={getSession}
            reactivateSession={reactivateSession}
          />
        </div>
      ),
    };
  };

  // to make sure, that productDetails.values belong to correct plan we should sort it
  const modifiedProductDetails = useMemo(() => {
    const productIds = (products || []).map((product) => product.id);
    const enterpriseProductId = products?.at(-1)?.id;

    const detailsWithSortedValues = (productDetails || []).map((detail) => {
      return {
        label: detail.label,
        values: [
          ...[...detail.values].sort((a, b) => {
            return productIds.indexOf(a.product_id) - productIds.indexOf(b.product_id);
          }),
        ],
      };
    });

    const sortedDetailsWithModifiedEnterpriseValues = detailsWithSortedValues.map((detail) => {
      return {
        label: detail.label,
        values: detail.values.map((val) =>
          enterpriseValuesModification(val, enterpriseProductId || ''),
        ),
      };
    });

    return sortedDetailsWithModifiedEnterpriseValues || [];
  }, [productDetails, products]);

  const handleRerunRequest = useCallback(() => {
    if (productsError) {
      productsRefetch();
    }
    if (productDetailsError) {
      productsDetailsRefetch();
    }
  }, [productDetailsError, productsDetailsRefetch, productsError, productsRefetch]);

  const errorContent = useMemo(
    () => (
      <div className={styles.errorWrapper}>
        <NoDataIcon />
        <div>It looks like the table didn’t load correctly.</div>
        <div>Please refresh or try again later.</div>
        <Button
          handleClick={handleRerunRequest}
          fullWidth={false}
          size="medium"
          styleType="outlinedViolet">
          Refresh
        </Button>
      </div>
    ),
    [handleRerunRequest],
  );

  if (tableDataIsLoading) {
    return (
      <Table
        className={styles.wrapper}
        columns={[DEFAULT_FIRST_COLUMN, ...loadingArray].map(renderTableSkeletonHeader)}
        renderItem={(item: number, index) => (
          <MainFeaturesTableContentSkeleton index={index} key={item} />
        )}
        items={loadingArray}
      />
    );
  }

  if (tableError) {
    return errorContent;
  }

  return (
    <WithLoader isLoading={stripeIsLoading}>
      <Table
        className={styles.wrapper}
        columns={[DEFAULT_FIRST_COLUMN, ...(products || [])].map(renderTableHeader)}
        items={modifiedProductDetails || []}
        renderItem={renderTableCells}
      />
    </WithLoader>
  );
};

export default memo(MainFeatures);
