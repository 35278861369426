import React from 'react';

import cx from 'classnames';

import Skeleton from 'components/skeleton';
import WithSpacing from 'components/with-spacing';

import styles from './SimpleCurrentSubscriptionCard.module.scss';

interface AppActionButtonsSkeletonProps {
  showBorder?: boolean;
}

export const SimpleCurrentSubscriptionSkeleton: React.FC<AppActionButtonsSkeletonProps> = ({
  showBorder = false,
}) => {
  return (
    <div className={styles.wrapperPlanElementSkeleton}>
      <WithSpacing spaceSize="large">
        <Skeleton style={{ width: 65, height: 14 }} />
      </WithSpacing>
      <div>
        <Skeleton type="Avatar" style={{ height: 24, width: 24 }} />
        <div className={cx({ [styles.info]: showBorder })}>
          <Skeleton style={{ width: 150, height: 16 }} />
        </div>
      </div>
    </div>
  );
};

export const SimpleCurrentSubscriptionActionButtonsSkeleton = () => {
  return (
    <div className={styles.buttonsSkeleton}>
      <Skeleton style={{ width: 96, height: 40, borderRadius: 12 }} />
      <Skeleton style={{ width: 175, height: 40, borderRadius: 12 }} />
    </div>
  );
};
