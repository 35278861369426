import React from 'react';

import cx from 'classnames';

import Skeleton from 'components/skeleton';

import styles from './MainFeatures.module.scss';
import { loadingArray } from './mainFeatures.utils';

export const MainFeaturesTableContentSkeleton = ({ index }: { index: number }) => {
  return (
    <tr className={cx({ [styles.rowColored]: index % 2, [styles.skeletonTr]: true })}>
      <td className={styles.headerSkeletonWrapper}>
        <Skeleton style={{ width: index < 2 ? 120 : 180, height: 24 }} />
      </td>
      {loadingArray.map((_, i) => (
        <td key={i}>
          <Skeleton style={{ width: 120, height: 24 }} />
        </td>
      ))}
    </tr>
  );
};

export const MainFeaturesTableHeaderSkeleton = () => {
  return (
    <div className={styles.skeletonHeaderElement}>
      <Skeleton style={{ width: 40, height: 16 }} />
      <Skeleton style={{ width: 18, height: 14 }} />
      <Skeleton style={{ width: 120, height: 40, borderRadius: 12 }} />
    </div>
  );
};
