import React, { useRef, useState } from 'react';

import cx from 'classnames';

import { ReactComponent as Copy } from 'assets/icons/copy.svg';

import styles from './InputWithCopyIcon.module.scss';

interface InputProps {
  type?: string;
  placeholder?: string;
  value?: string | null;
  handleChange?: (text: string) => void;
  icon?: React.ReactElement;
  copy?: boolean;
  className?: string;
}

const InputWithCopyIcon: React.FC<InputProps> = ({
  type,
  placeholder,
  className = '',
  value,
  handleChange,
  icon,
  copy,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const copyRef = useRef<HTMLButtonElement>(null);
  const [copied, setCopied] = useState<boolean>(false);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const copyToClipboard = (evt: React.SyntheticEvent) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    const target = evt.target as HTMLElement;
    setCopied(true);
    inputRef?.current?.select();
    document.execCommand('copy');
    target.focus();

    timer.current = setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div className={cx(styles.wrapper, className)}>
      <input
        name="name"
        type={type}
        readOnly={copy}
        placeholder={placeholder}
        value={value || ''}
        ref={inputRef}
        onChange={(e) => handleChange && handleChange(e.target.value)}
        className={cx(styles.input, copy && styles.readOnly)}
      />
      {icon && <div className={styles.icon}>{icon}</div>}
      {copy && (
        <button type="button" ref={copyRef} className={styles.copyButton} onClick={copyToClipboard}>
          <Copy />
        </button>
      )}
      {copy && copied && <div className={styles.copyStatus}>Copied</div>}
    </div>
  );
};

export default InputWithCopyIcon;
