import React, { memo } from 'react';

import cx from 'classnames';

import { Children } from 'types/childrenTypes';
import { NotificationType } from 'types/notificationTypes';

import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';

import styles from './Notification.module.scss';

interface NotificationProps {
  type: NotificationType;
  className?: string;
  children: Children;
  onClose?: () => void;
}

const Notification: React.FC<NotificationProps> = ({
  type,
  children,
  className = '',
  onClose,
}): React.ReactElement => {
  return (
    <div
      className={cx({
        [styles.wrapper]: true,
        [styles[type]]: type,
        [className]: className,
      })}>
      {children}
      {typeof onClose === 'function' && (
        <button onClick={onClose} className={styles.closeButton}>
          <CloseIcon />
        </button>
      )}
    </div>
  );
};

export default memo(Notification);
