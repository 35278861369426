import React from 'react';

import Skeleton from 'components/skeleton';

const ApiKeyRowSkeleton = (): React.ReactElement => {
  return (
    <>
      <td align={'left'}>
        <Skeleton style={{ width: 80, height: 24 }} />
      </td>
      <td align={'left'}>
        <Skeleton type="Avatar" style={{ width: 24, height: 24, marginRight: 16 }} />
        <Skeleton style={{ width: 120, height: 24 }} />
      </td>
      <td align={'left'}>
        <Skeleton style={{ width: 120, height: 40, borderRadius: 12 }} />
      </td>
      <td align={'center'}>
        <Skeleton style={{ width: 120, height: 40, borderRadius: 12 }} />
      </td>
    </>
  );
};

export default ApiKeyRowSkeleton;
