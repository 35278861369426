import React, { useEffect } from 'react';

import { Route, Routes, useLocation } from 'react-router-dom';

import { userDataSelector, userLoadingSelector } from 'selectors/userSlice.selectors';
import { useLazyGetUserQuery } from 'services/userService';
import { showDefaultNotification } from 'slices/notificationSlice';
import { setIsLoading } from 'slices/userSlice';
import { useAppDispatch, useAppSelector } from 'store';

import Layout from 'containers/layout';
import LoginLayout from 'containers/login-layout';
import ModalContactUs from 'containers/modal-contact-us';
import Preloader from 'containers/preloader';

import { AUTH_ROUTES, NON_AUTH_ROUTES, RouteType } from 'constants/routes';
import { SESSION_STORAGE_FF_KEY } from 'constants/sessionStorageKeys';
import { WATCHDATA_REFRESH_TOKEN, WATCHDATA_TOKEN } from 'constants/token';

import useLocalStorage from 'utils/useLocalStorage.hook';

import styles from './App.module.scss';
import AppNotification from './appNotification';

const renderRoutes = (route: RouteType, i: number): React.ReactElement => (
  <Route key={`${i}${route.path}`} path={route.path} element={route.element} />
);

const SUBSCRIPTION_SUCCESS = {
  text: 'Payment completed successfully! Your subscription will be activated within 5 minutes.',
  notificationType: 'success',
};
const SUBSCRIPTION_FAIL = {
  text: 'Order has been cancelled.',
  notificationType: 'fail',
};

const App = () => {
  const location = useLocation();
  const user = useAppSelector(userDataSelector);
  const isLoading = useAppSelector(userLoadingSelector);
  const dispatch = useAppDispatch();

  const [token] = useLocalStorage(WATCHDATA_TOKEN);
  const [refreshToken] = useLocalStorage(WATCHDATA_REFRESH_TOKEN);

  const [getUser] = useLazyGetUserQuery();

  const isAuth = !!(token && refreshToken && user?.email);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const featureFlag = params.get('feature');
      const subscription = params.get('subscription');

      if (subscription) {
        dispatch(
          showDefaultNotification(
            subscription === 'success' ? SUBSCRIPTION_SUCCESS : SUBSCRIPTION_FAIL,
          ),
        );
      }
      if (featureFlag) {
        window.sessionStorage.setItem(SESSION_STORAGE_FF_KEY, featureFlag);
      }
    }
  }, [dispatch, location]);

  useEffect(() => {
    if (token && refreshToken && !user?.email) {
      dispatch(setIsLoading(true));
      getUser().finally(() => dispatch(setIsLoading(false)));
    }
  }, [dispatch, getUser, refreshToken, token, user?.email]);

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Preloader />
      </div>
    );
  }

  return (
    <>
      <ModalContactUs />
      <AppNotification />
      {isAuth ? (
        <Layout>
          <Routes>{AUTH_ROUTES.map(renderRoutes)}</Routes>
        </Layout>
      ) : (
        <LoginLayout>
          <Routes>{NON_AUTH_ROUTES.map(renderRoutes)}</Routes>
        </LoginLayout>
      )}
    </>
  );
};

export default App;
