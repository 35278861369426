// STYLES
import React from 'react';

import cx from 'classnames';

// TYPES
import { Children } from 'types/childrenTypes';

import styles from './WithLabel.module.scss';

interface WithLabelProps {
  children: Children;
  label: string;
  isBold?: boolean;
  required?: boolean;
}

const WithLabel: React.FC<WithLabelProps> = ({
  children,
  label,
  isBold = false,
  required = false,
}) => {
  return (
    <>
      {label && (
        <div
          className={cx({
            [styles.label]: true,
            [styles.bold]: isBold,
          })}>
          {label}
          {required && ' *'}
        </div>
      )}
      {children}
    </>
  );
};

export default WithLabel;
