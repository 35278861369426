import React, { useCallback, useMemo, useState } from 'react';

import EnterpriseFooter from 'pages/pricing/enterprise-footer';
import { Link } from 'react-router-dom';

import { useLazyGetSessionQuery } from 'services/paymentService';
import { useGetProductQuery } from 'services/productService';
import { setOpenContactUsModal } from 'slices/contactUsModalSlice';
import { setShowError } from 'slices/notificationSlice';
import { useAppDispatch } from 'store';

import Button from 'components/button';
import SectionLabel from 'components/section-label';
import Switch from 'components/switch';
import WithLoader from 'components/with-loader';
import WithSpacing from 'components/with-spacing';
import Product from 'containers/product';

import { COMPARE_PLANS_ROUTE, PRICING_ROUTE } from 'constants/routes';
import { ProductType } from 'types/productTypes';

import { ReactComponent as NoDataIcon } from 'assets/icons/no-data-icon.svg';

import styles from './DefaultPricingView.module.scss';

const loadingArray = Array.from({ length: 4 }, (_, i) => i);

const DefaultPricingView = () => {
  const dispatch = useAppDispatch();
  const [isAnnual, setIsAnnual] = useState(false);

  const [getSession, { isFetching }] = useLazyGetSessionQuery();
  const {
    data: products = [],
    isLoading: productsIsLoading,
    isError: productsError,
    refetch: producstRefetch,
  } = useGetProductQuery('SUBSCRIPTION');

  const buyWithCrypto = useCallback(
    (id: string) => {
      dispatch(
        setOpenContactUsModal({
          modalType: 'BUY_WITH_CRYPTO',
          productId: id,
        }),
      );
    },
    [dispatch],
  );

  const buyWithFiat = useCallback(
    (id: string) => {
      // safari blocks window.open in async actions that's why we use workaround
      const windowOpened = window.open();

      const BASE_URL = `${window.location.origin}${PRICING_ROUTE}`;
      getSession({
        success_url: `${BASE_URL}?subscription=success`,
        cancel_url: `${BASE_URL}?subscription=fail`,
        subscribe_model: {
          product_id: id,
        },
      })
        .then((res) => {
          if (res?.data?.checkout_session_url) {
            windowOpened!.location = res?.data?.checkout_session_url as string;
          }
        })
        .catch(() => {
          dispatch(setShowError(true));
        });
    },
    [dispatch, getSession],
  );

  const renderProduct = useCallback(
    (product: ProductType | number) => {
      if (typeof product === 'number') {
        return <Product isLoading key={product} />;
      }

      return (
        <Product
          {...product}
          buyWithCrypto={buyWithCrypto}
          buyWithFiat={buyWithFiat}
          disabled={product.type === 'DEFAULT'}
          key={product.name}
          theme={product.theme === 'Business' ? 'black' : 'white'}
        />
      );
    },
    [buyWithCrypto, buyWithFiat],
  );

  const productsToRender = productsIsLoading
    ? loadingArray.map(renderProduct)
    : products.map(renderProduct).slice(0, products.length - 1);

  const handleRerunRequest = useCallback(() => {
    producstRefetch();
  }, [producstRefetch]);

  const showErrorContent = productsError && !productsIsLoading;

  const errorContent = useMemo(
    () => (
      <div className={styles.errorWrapper}>
        <NoDataIcon />
        <div>It looks like the plans didn’t load correctly.</div>
        <div>Please refresh or try again later.</div>
        <Button
          handleClick={handleRerunRequest}
          fullWidth={false}
          size="medium"
          styleType="outlinedViolet">
          Refresh
        </Button>
      </div>
    ),
    [handleRerunRequest],
  );

  return (
    <WithLoader isLoading={isFetching}>
      <WithSpacing spaceSize="xextra-large">
        <SectionLabel>Pricing</SectionLabel>
      </WithSpacing>
      <WithSpacing className={styles.info}>
        <WithSpacing placement="right">
          <span>We&lsquo;ll set you up with everything you need to get started immediately</span>
        </WithSpacing>
        <Switch
          disabled
          onChange={setIsAnnual}
          checked={isAnnual}
          labels={['Monthly', 'Annual (15% off)']}
        />
      </WithSpacing>
      {showErrorContent ? (
        errorContent
      ) : (
        <>
          <WithSpacing spaceSize="large" fullWidth className={styles.productsWrapperPlacement}>
            <div className={styles.productsWrapper}>{productsToRender}</div>
          </WithSpacing>
          <WithSpacing className={styles.comparePlansButton} spaceSize="xextra-large">
            <Link to={COMPARE_PLANS_ROUTE} className={styles.link}>
              <Button fullWidth={false} styleType="outlinedViolet" size="large">
                Compare Plans
              </Button>
            </Link>
          </WithSpacing>
          <EnterpriseFooter product={products?.at(-1)} />
        </>
      )}
    </WithLoader>
  );
};

export default DefaultPricingView;
