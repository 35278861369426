import React, { Suspense, useCallback, useMemo, useState } from 'react';

import { useGetOverallStatisticQuery } from 'services/statsService';
import { useGetApiKeysQuery, useGetPaymentsQuery } from 'services/userService';

import Button from 'components/button/button';
import SectionLabel from 'components/section-label';
import WithSpacing from 'components/with-spacing';
import AppStatisticCard from 'containers/app-statistic-card';
import Info from 'containers/info';
import ModalCreateApp from 'containers/modal-create-app';

import { ReactComponent as NoDataIcon } from 'assets/icons/no-data-icon.svg';

import styles from './Home.module.scss';
import AppsTable from './apps_table';

const Cards = React.lazy(() => import('./cards'));

const Home = () => {
  const [showCreateKeyModal, setShowCreateKeyModal] = useState(false);

  const {
    error: paymentError,
    refetch: paymentsRefetch,
    isError: paymentsIsError,
    isFetching: paymentsIsFetching,
  } = useGetPaymentsQuery();

  const {
    refetch: apiKeysRefetch,
    isError: apiKeysIsError,
    isFetching: apiKeysIsFetching,
  } = useGetApiKeysQuery();

  const {
    data: overallStatisticsData,
    refetch: overallStatisticsRefetch,
    isError: overallStatisticsIsError,
    isFetching: overallStatisticsIsFetching,
  } = useGetOverallStatisticQuery();

  const STATISTIC_CONFIG = useMemo(
    () => [
      {
        label: 'Watch Units used / month',
        value: overallStatisticsData?.watchunit_used?.toLocaleString(),
      },
      {
        label: 'Remain Watch Units',
        value: overallStatisticsData?.watchunit_remain?.toLocaleString(),
      },
      {
        label: 'Total Requests / 24h',
        value: overallStatisticsData?.total_requests?.toLocaleString(),
      },
    ],
    [overallStatisticsData],
  );

  const onAddApiKey = useCallback(() => {
    setShowCreateKeyModal(true);
  }, []);

  const closeModal = useCallback(() => setShowCreateKeyModal(false), []);

  const handleRerunRequest = useCallback(() => {
    if (apiKeysIsError) {
      apiKeysRefetch();
    }
    if (paymentError) {
      paymentsRefetch();
    }
  }, [apiKeysRefetch, apiKeysIsError, paymentError, paymentsRefetch]);

  const isError = paymentsIsError || apiKeysIsError;
  const isLoading = paymentsIsFetching || apiKeysIsFetching;

  const showCardsError = !isLoading && isError;

  const cardsError = useMemo(
    () => (
      <div className={styles.errorWrapper}>
        <NoDataIcon />
        <div>It looks like the cards didn’t load correctly.</div>
        <div>Please refresh or try again later.</div>
        <Button
          handleClick={handleRerunRequest}
          fullWidth={false}
          size="medium"
          styleType="outlinedViolet">
          Refresh
        </Button>
      </div>
    ),
    [handleRerunRequest],
  );

  return (
    <>
      <ModalCreateApp onClose={closeModal} visible={showCreateKeyModal} />
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Suspense>{showCardsError ? cardsError : <Cards onAddApiKey={onAddApiKey} />}</Suspense>
          <WithSpacing spaceSize="extra-large" fullWidth>
            <AppStatisticCard
              isLoading={overallStatisticsIsFetching}
              elements={STATISTIC_CONFIG}
              isError={overallStatisticsIsError}
              handleRerunRequest={overallStatisticsRefetch}
            />
          </WithSpacing>
          <WithSpacing spaceSize="large">
            <SectionLabel>My application list</SectionLabel>
          </WithSpacing>
          <WithSpacing spaceSize="xextra-large" className={styles.tableWrapper}>
            <AppsTable onAddApiKey={onAddApiKey} handleRerunRequest={handleRerunRequest} />
          </WithSpacing>
          <Info />
        </div>
      </div>
    </>
  );
};

export default Home;
