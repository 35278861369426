import React from 'react';

import Loader from 'components/loader';

import { Children } from 'types/childrenTypes';

import styles from './WithLoader.module.scss';

interface WithLoaderProps {
  children: Children;
  isLoading: boolean;
}

const WithLoader: React.FC<WithLoaderProps> = ({ children, isLoading }): React.ReactElement => {
  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }
  return <>{children}</>;
};

export default WithLoader;
