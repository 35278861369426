import React from 'react';

import cx from 'classnames';

import WithSkeletonLoader from 'components/with-skeleton-loader';
import WithSpacing from 'components/with-spacing';
import { PlanElementSkeleton } from 'containers/plan-element/planElement.skeleton';

import styles from './PlanElement.module.scss';

interface PlanElementProps {
  sectionLabel: string;
  info?: string;
  elementIcon?: React.ReactElement;
  showBorder?: boolean;
  isLoading?: boolean;
  showSkeletonAvatar?: boolean;
  sectionLabelStyle?: 'default' | 'error';
}

const PlanElement: React.FC<PlanElementProps> = ({
  sectionLabel,
  info = '',
  elementIcon = null,
  showBorder = true,
  isLoading,
  showSkeletonAvatar,
  sectionLabelStyle = 'default',
}) => {
  return (
    <WithSkeletonLoader
      isLoading={!!isLoading}
      skeletonLoader={
        <PlanElementSkeleton showBorder={showBorder} showIcon={showSkeletonAvatar} />
      }>
      <div className={styles.wrapper}>
        <WithSpacing spaceSize="extra-medium">
          <span className={cx({ [styles.error]: sectionLabelStyle === 'error' })}>
            {sectionLabel}
          </span>
        </WithSpacing>
        <div>
          {elementIcon}
          <div className={cx({ [styles.info]: showBorder })}>{info}</div>
        </div>
      </div>
    </WithSkeletonLoader>
  );
};

export default PlanElement;
