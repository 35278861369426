import React, { useState } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';

import Button from 'components/button';
import Form from 'components/form';
import Icon from 'components/icon';
import WithLabel from 'components/with-label';
import InputWithCopyIcon from 'containers/input-with-copy-icon';

import { LOGIN_SIGNUP_FORM_NAME } from 'constants/analyticsTags';

import MailIcon from 'assets/icons/mail.svg';

import styles from './EmailForm.module.scss';

interface EmailFormProps {
  onEmailSubmit: ({ email }: { email: string }) => void;
  signup?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
}

const INVALID_ERROR = 'Please enter a valid email address.';
const EMAIL_REGEX =
  /* eslint-disable no-useless-escape */
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const validateEmail = (email: string) => EMAIL_REGEX.test(email);

const EmailForm: React.FC<EmailFormProps> = ({ onEmailSubmit, isLoading, signup, disabled }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [verified, setVerified] = useState(false);

  const emailInputIsEmpty = !email?.trim().length;
  const userLang = navigator.language;
  const submitButtonIsDisabled = emailInputIsEmpty || disabled || (!verified && signup);
  const showCaptcha = !!process.env.REACT_APP_CAPTCHA_KEY && !verified;

  const handleSubmit = () => {
    if (!validateEmail(email)) {
      return setError(INVALID_ERROR);
    }
    onEmailSubmit({ email });
  };

  const handleEmailChange = (e: string) => {
    setError('');
    setEmail(e);
  };

  const submitContent = showCaptcha ? (
    <ReCAPTCHA
      onChange={() => setVerified(true)}
      hl={userLang}
      sitekey={process.env.REACT_APP_CAPTCHA_KEY as string}
    />
  ) : (
    <Button
      handleClick={handleSubmit}
      styleType="filled"
      loading={isLoading}
      disabled={submitButtonIsDisabled}
      size="large">
      {signup ? 'Sign Up' : 'Log In'}
    </Button>
  );

  return (
    <Form className={styles.form} name={LOGIN_SIGNUP_FORM_NAME}>
      <WithLabel label="Enter your email">
        <InputWithCopyIcon
          className={error && styles.error}
          value={email}
          handleChange={handleEmailChange}
          placeholder="example@gmail.com"
          icon={<Icon src={MailIcon} alt="e-mail icon" size="small" />}
        />
      </WithLabel>
      {error && <div className={styles.errorWrapper}>{error}</div>}
      <div className={styles.submitContent}>{submitContent}</div>
    </Form>
  );
};

export default EmailForm;
