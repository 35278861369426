import initialApiService from 'services/initialService';

import { BlockchainType } from 'types/blockchainTypes';

export const infoApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getBlockChainList: builder.query<BlockchainType[], void>({
      query: () => 'info/blockchain_list',
    }),
    getBlockChainTokensForPayment: builder.query<string[], void>({
      query: () => 'info/tokens_for_payment',
    }),
  }),
});

export const { useGetBlockChainListQuery, useGetBlockChainTokensForPaymentQuery } = infoApiService;
