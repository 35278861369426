import { SERVICES_TAGS } from 'constants/servicesTags';
import initialApiService from 'services/initialService';

import { ApiKeyType } from 'types/apiKeyTypes';
import { PaginationType } from 'types/paginationTypes';
import { PaymentType } from 'types/paymentTypes';
import { UserType } from 'types/userTypes';

export const userApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<UserType, void>({
      query: () => 'user',
    }),
    getPayments: builder.query<PaymentType[], void>({
      query: () => 'user/payments',
      providesTags: [SERVICES_TAGS.API_KEYS],
    }),
    getApiKeys: builder.query<
      { data: ApiKeyType[]; pagination: PaginationType },
      PaginationType | void
    >({
      query: (pagination) =>
        pagination
          ? `user/api_keys?limit=${pagination.limit}&offset=${pagination.offset}`
          : 'user/api_keys',
      providesTags: [SERVICES_TAGS.API_KEYS],
    }),
    getApiKey: builder.query<ApiKeyType, string>({
      query: (id) => `user/api_key?api_key_id=${id}`,
    }),
    addApiKey: builder.mutation({
      query: (params) => ({
        url: 'user/api_key',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: [SERVICES_TAGS.API_KEYS],
    }),
    updateApiKey: builder.mutation<ApiKeyType, Pick<ApiKeyType, 'id' | 'blockchain' | 'name'>>({
      query: (params) => ({
        url: 'user/api_key',
        method: 'PATCH',
        body: params,
      }),
    }),
    deleteApiKey: builder.mutation({
      query: (id: string | number) => ({
        url: 'user/api_key',
        method: 'DELETE',
        body: { id },
      }),
    }),
  }),
});

export const getUserEndpoint = userApiService.endpoints.getUser;

export const {
  useGetPaymentsQuery,
  useLazyGetPaymentsQuery,
  useLazyGetUserQuery,
  useLazyGetApiKeysQuery,
  useGetApiKeyQuery,
  useLazyGetApiKeyQuery,
  useGetApiKeysQuery,
  useUpdateApiKeyMutation,
  useDeleteApiKeyMutation,
  useAddApiKeyMutation,
} = userApiService;
