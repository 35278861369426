import { PaymentType } from "types/paymentTypes";

const isBeforeToday = (date: Date) => {
  const today = new Date();

  today.setHours(0, 0, 0, 0);

  return date < today;
}

export const getCurrentPlan = (payments: PaymentType[]): PaymentType | undefined => {
  return payments?.find(
    (payment) => {
      return payment.type === 'SUBSCRIPTION'
    }
  ) as PaymentType;
}

export const userHasPlan = (payment?: PaymentType): boolean => {
  if(!payment) {
    return false;
  }

  return payment?.product?.type !== 'DEFAULT';
}


export const userPlanHasNoRenewalOrExpired = (payment?: PaymentType): boolean => {
  if(!payment) {
    return false;
  }

  return Number(payment?.status) > 1;
}
