import React from 'react';

import { Dropdown as DropdownAntd } from 'antd';

import { Children } from 'types/childrenTypes';
import { DropdownPlacement, DropdownTriggerTypes } from 'types/dropdownTypes';

import 'antd/lib/dropdown/style/index.css';

interface DropdownProps {
  children: Children;
  trigger?: DropdownTriggerTypes;
  overlay: React.ReactElement;
  visible?: boolean;
  disabled?: boolean;
  placement?: DropdownPlacement;
  onVisibleChange?: (e: boolean) => void;
  getPopupContainer?: (node: HTMLElement) => HTMLElement;
}

const Dropdown: React.FC<DropdownProps> = ({
  children,
  trigger = ['click'],
  overlay,
  visible,
  placement = 'bottomLeft',
  onVisibleChange,
  getPopupContainer,
  disabled = false,
}) => {
  return (
    <DropdownAntd
      disabled={disabled}
      getPopupContainer={getPopupContainer}
      overlay={overlay}
      trigger={trigger}
      visible={visible}
      placement={placement}
      onVisibleChange={onVisibleChange}>
      {children}
    </DropdownAntd>
  );
};

export default Dropdown;
