import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useLazySignInQuery, useLazyVerifyEmailQuery } from 'services/auth/authService';
import { useLazyGetUserQuery } from 'services/userService';
import { setIsLoading } from 'slices/userSlice';

import EmailForm from 'containers/email-form';

import { HOME_ROUTE, LOGIN_ROUTE } from 'constants/routes';
import { WATCHDATA_REFRESH_TOKEN, WATCHDATA_TOKEN } from 'constants/token';

import useLocalStorage from 'utils/useLocalStorage.hook';
import useQueryParamsHook from 'utils/useQueryParams.hook';

import styles from './Login.module.scss';
import LoginCheckEmail from './login-check-email';

const Login = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { paramsObject } = useQueryParamsHook();

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const [token, setToken] = useLocalStorage(WATCHDATA_TOKEN);
  const [refreshToken, setRefreshToken] = useLocalStorage(WATCHDATA_REFRESH_TOKEN);

  const [verifyEmail, { isLoading: verifyEmailIsFetching }] = useLazyVerifyEmailQuery();
  const [getUser, { data: user }] = useLazyGetUserQuery();
  const [signIn, { isLoading: singInIsFetching }] = useLazySignInQuery();

  const getUserDataAndToken = useCallback(async () => {
    if (paramsObject?.token && !token && !refreshToken) {
      dispatch(setIsLoading(true));
      signIn(paramsObject?.token)
        .then((res) => {
          if ('error' in res) {
            console.error('token expired');
            navigate(LOGIN_ROUTE);
          } else {
            setToken(res.data?.access_token?.toString());
            setRefreshToken(res.data?.refresh_token?.toString());
          }
        })
        .then(() => {
          getUser();
        })
        .finally(() => dispatch(setIsLoading(false)));
    } else if (localStorage.getItem(WATCHDATA_TOKEN)) {
      dispatch(setIsLoading(true));
      getUser().finally(() => dispatch(setIsLoading(false)));
    }
  }, [
    dispatch,
    getUser,
    navigate,
    paramsObject?.token,
    refreshToken,
    setRefreshToken,
    setToken,
    signIn,
    token,
  ]);

  const authProcess = useCallback(async () => {
    try {
      await getUserDataAndToken();
    } catch (error) {
      throw new Error('Oauth login failed');
    }
  }, [getUserDataAndToken]);

  useEffect(() => {
    authProcess();
  }, [authProcess, dispatch]);

  useEffect(() => {
    user && user.email && navigate(HOME_ROUTE);
  }, [user, navigate]);

  const handleModalOpenClose = () => setIsModalOpened((prevState) => !prevState);

  const handleVerifyEmail = ({ email }: { email: string }) => {
    verifyEmail({
      email,
      sign_in_url: `${window.location.origin}${LOGIN_ROUTE}?token`,
    }).then(() => {
      setEmail(email);
      handleModalOpenClose();
    });
  };

  const isLoading = verifyEmailIsFetching || singInIsFetching;

  return (
    <>
      <h2 className={styles.title}>Welcome back!</h2>
      <div className={styles.subtitle}>We&lsquo;re waiting for you</div>
      <EmailForm isLoading={isLoading} onEmailSubmit={handleVerifyEmail} />
      <LoginCheckEmail isVisible={isModalOpened} handleClose={handleModalOpenClose} email={email} />
    </>
  );
};

export default Login;
