import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { useReactivateSessionMutation } from 'services/paymentService';
import { useGetPaymentsQuery } from 'services/userService';

import SectionLabel from 'components/section-label';
import Switch from 'components/switch';
import WithSpacing from 'components/with-spacing';

import { PRICING_ROUTE } from 'constants/routes';

import { ReactComponent as ArrowBack } from 'assets/icons/arrow-left.svg';

import { getCurrentPlan, userHasPlan } from 'utils/payment.utils';

import styles from './ComparePlans.module.scss';
// import AdditionalFeatures from './additional-features';
import MainFeatures from './main-features';
import SimpleCurrentSubscription from './simple-current-subscription';

const ComparePlans = () => {
  const [isAnnual, setIsAnnual] = useState(false);

  const { data: payments = [] } = useGetPaymentsQuery();

  const [reactivateSession, { isLoading: reactivateSessionIsLoading }] =
    useReactivateSessionMutation();

  const userCurrentPlan = getCurrentPlan(payments);

  const isNonDefaultPlan = userHasPlan(userCurrentPlan);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.goBackWrapper}>
          <Link to={PRICING_ROUTE}>
            <ArrowBack />
          </Link>
          <SectionLabel>{isNonDefaultPlan ? 'My subscription' : 'Pricing Details'}</SectionLabel>
        </div>
        {!isNonDefaultPlan && (
          <Switch
            disabled
            onChange={setIsAnnual}
            checked={isAnnual}
            labels={['Monthly', 'Annual (15% off)']}
          />
        )}
      </div>
      {isNonDefaultPlan && (
        <WithSpacing spaceSize="xextra-large" fullWidth>
          <SimpleCurrentSubscription
            reactivateSession={reactivateSession}
            isLoading={reactivateSessionIsLoading}
          />
        </WithSpacing>
      )}
      <MainFeatures reactivateSession={reactivateSession} isLoading={reactivateSessionIsLoading} />
      {/* Will be removed for now */}
      {/* <AdditionalFeatures /> */}
    </div>
  );
};

export default ComparePlans;
