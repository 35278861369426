import React from 'react';

import { Skeleton as AntdSkeleton } from 'antd';

import 'antd/lib/skeleton/style/css';

interface SkeletonProps {
  type?: 'Button' | 'Avatar' | 'Input' | 'Image';
  style?: { [key: string]: string | number };
  round?: boolean;
  className?: string;
}

const Skeleton: React.FC<SkeletonProps> = ({ style = {}, type = 'Button', className = '' }) => {
  switch (type) {
    case 'Button':
      return (
        <AntdSkeleton.Button
          active
          shape="round"
          style={{ background: '#e0e0e9', ...style }}
          className={className}
        />
      );
    case 'Avatar':
      return (
        <AntdSkeleton.Avatar
          active
          style={{ background: '#e0e0e9', ...style }}
          className={className}
        />
      );
    // case 'Input':
    //   return (Component = AntdSkeleton.Input);
    // case 'Image':
    //   return (Component = AntdSkeleton.Image);
    default:
      return null;
  }
};

export default Skeleton;
