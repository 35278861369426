import React from 'react';

import Skeleton from 'components/skeleton';
import WithSpacing from 'components/with-spacing';

import styles from './Product.module.scss';

const ProductSkeleton = () => {
  return (
    <div className={styles.product}>
      <WithSpacing className={styles.skeletonRowWrapper} spaceSize="large">
        <Skeleton style={{ height: 24, width: 24 }} type="Avatar" />
        <Skeleton style={{ height: 20, width: 80 }} />
      </WithSpacing>
      <Skeleton style={{ height: 16, width: 180 }} />
      <WithSpacing spaceSize="large">
        <Skeleton style={{ height: 16, width: 80 }} />
      </WithSpacing>
      <WithSpacing spaceSize="large">
        <Skeleton style={{ height: 24, width: 60 }} />
      </WithSpacing>
      <WithSpacing spaceSize="large" fullWidth className={styles.skeletonButton}>
        <Skeleton style={{ height: 56, width: '100%', borderRadius: 12 }} />
      </WithSpacing>
      <ul className={styles.skeletonList}>
        <li>
          <Skeleton style={{ height: 12, width: 120 }} />
        </li>
        <li>
          <Skeleton style={{ height: 12, width: 80 }} />
        </li>
        <li>
          <Skeleton style={{ height: 12, width: 100 }} />
        </li>
      </ul>
    </div>
  );
};

export default ProductSkeleton;
