export const getCurrentPage = (offset?: number | string, limit?: number | string): number => {
  const limitNumber = Number(limit);
  const offsetNumber = Number(offset);

  if (!limitNumber || !offsetNumber) {
    return 1;
  }

  return Math.ceil(offsetNumber / limitNumber) + 1;
};
