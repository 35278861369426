import React, { memo } from 'react';

import WithSpacing from 'components/with-spacing';

import styles from './Info.module.scss';

type ContentType = {
  title: string;
  link: {
    label: string;
    href: string;
  };
  details: string[];
};

const INFO_CONTENT: ContentType[] = [
  {
    title: 'Documentation',
    link: {
      label: 'View documentation',
      href: 'https://cryptocorp.ducalis.io/wacthdata-product-backlog',
    },
    details: ['Product overview', 'Detailed walkthroughts', 'Video tutorials', 'Industry updates'],
  },
  {
    title: 'Changelog',
    link: {
      label: 'View changelog',
      href: 'https://docs.watchdata.io/introduction/change-log-update',
    },
    details: ['Version updates', 'New feature releases', 'Bug fixes', 'Product improvements'],
  },
  {
    title: 'FAQ',
    link: {
      label: 'Show more',
      href: 'https://docs.watchdata.io/resources/faq',
    },
    details: [
      'Where can I view my plan details after I claim it?',
      "Why can't I claim the Limited Free plan?",
      'What will happen if my plan expires?',
      'How to customize my plan',
    ],
  },
];

const detailsRender = (detail: string): React.ReactElement => <li key={detail}>{detail}</li>;

const infoRender = (content: ContentType): React.ReactElement => (
  <div key={content.title}>
    <WithSpacing spaceSize="extra-medium">
      <div className={styles.title}>{content.title}</div>
    </WithSpacing>
    <WithSpacing spaceSize="extra-medium">
      <ul>{content.details.map(detailsRender)}</ul>
    </WithSpacing>
    <a href={content.link.href} target="_blank">
      {content.link.label}
    </a>
  </div>
);

const Info = () => {
  return <div className={styles.wrapper}>{INFO_CONTENT.map(infoRender)}</div>;
};

export default memo(Info);
