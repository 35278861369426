import { createSlice } from '@reduxjs/toolkit';

import { getUserEndpoint } from 'services/userService';

import { UserType } from 'types/userTypes';

export const SLICE_NAME = 'userSlice';

type UserSliceState = {
  user: UserType | null;
  isLoading: boolean;
};

const initialState: UserSliceState = {
  user: null,
  isLoading: false,
};

export const userSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    clearUser(state) {
      state.user = null;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(getUserEndpoint.matchFulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addMatcher(getUserEndpoint.matchRejected, (state) => {
      state.user = null;
    });
  },
});

export const { setUser, clearUser, setIsLoading } = userSlice.actions;

export default userSlice.reducer;
