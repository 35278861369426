import React from 'react';

import cx from 'classnames';

import { ReactComponent as SpinnerImage } from 'assets/icons/spinner.svg';

import styles from './Spinner.module.scss';

interface SpinnerProps {
  size?: 'small' | 'medium';
}

const Spinner: React.FC<SpinnerProps> = ({ size = 'medium' }) => {
  return <SpinnerImage className={cx({ [styles.loader]: true, [styles[size]]: true })} />;
};

export default Spinner;
