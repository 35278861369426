import React, { useEffect, useRef, useState } from 'react';

import data from 'animations/data.json';
import lottie from 'lottie-web';

const Preloader = () => {
  const loader = useRef(null);
  const [inited, setInited] = useState(false);

  useEffect(() => {
    if (!inited) {
      setInited(true);
      lottie.loadAnimation({
        container: loader.current as unknown as Element,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: data,
        assetsPath: '/images/',
      });
    }
  }, [inited]);

  return <div ref={loader} />;
};

export default Preloader;
