import React, { useCallback, useEffect, useRef } from 'react';

import { notificationDefaultNotificationTextSelector } from 'selectors/notificationSlice.selectors';
import { closeDefaultNotification } from 'slices/notificationSlice';
import { useAppDispatch, useAppSelector } from 'store';

import Notification from 'components/notification';

import styles from './NotificationSuccess.module.scss';

const NotificationSuccess = () => {
  const dispatch = useAppDispatch();
  const text = useAppSelector(notificationDefaultNotificationTextSelector);

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const onClose = useCallback(() => {
    dispatch(closeDefaultNotification());
  }, [dispatch]);

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(onClose, 5000);

    return () => {
      timer.current = null;
    };
  }, [onClose, timer]);

  return (
    <Notification type="success" className={styles.wrapper} onClose={() => dispatch(onClose)}>
      {text}
    </Notification>
  );
};

export default NotificationSuccess;
