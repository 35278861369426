import React, { useCallback, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { userDataSelector } from 'selectors/userSlice.selectors';
import {
  useGetApiKeysQuery,
  useGetPaymentsQuery,
  useLazyGetApiKeyQuery,
} from 'services/userService';
import { setShowError } from 'slices/notificationSlice';
import { useAppDispatch } from 'store';

import Dropdown from 'components/dropdown';
import Label from 'components/label';
import WithSkeletonLoader from 'components/with-skeleton-loader';
import WithSpacing from 'components/with-spacing';
import ModalCreateApp from 'containers/modal-create-app';
import { SidebarAppSkeleton } from 'containers/side-bar/sidebar.skeleton';

import { APP_VIEW_ROUTE, HOME_ROUTE, PRICING_ROUTE, getAppWithIdRoute } from 'constants/routes';
import { ApiKeyType } from 'types/apiKeyTypes';

import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right-icon.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-round-icon.svg';

import { getCurrentPlan } from 'utils/payment.utils';

import styles from './SideBar.module.scss';

const SideBar = () => {
  const user = useSelector(userDataSelector);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [showCreateModalKey, setShowCreateKeyModal] = useState<boolean>(false);

  const [getApiKey, { data: currentKey }] = useLazyGetApiKeyQuery();
  const {
    data: payments = [],
    isLoading: paymentsIsLoading,
    error: paymentError,
  } = useGetPaymentsQuery();

  const {
    isFetching: keysIsLoading,
    data: apiKeysResponse,
    error: apiKeysError,
  } = useGetApiKeysQuery();

  const userCurrentPlan = getCurrentPlan(payments);
  const apiKeysPlanCount = userCurrentPlan?.product?.total_api_keys || 0;

  const appConfigIsLoading = paymentsIsLoading || keysIsLoading;
  const error = !!(paymentError || apiKeysError);

  useEffect(() => {
    if (location.pathname.includes(APP_VIEW_ROUTE)) {
      const id = location.pathname.split('?').shift()?.split('/').pop() || '';
      getApiKey(id);
    }
  }, [getApiKey, location.pathname]);

  useEffect(() => {
    if (error) {
      dispatch(setShowError(true));
    }
  }, [dispatch, error]);

  const closeDropdown = useCallback(() => {
    setDropdownVisible(false);
  }, []);

  const renderApiKey = useCallback(
    (apiKey: ApiKeyType) => (
      <Link
        to={getAppWithIdRoute(apiKey.id.toString())}
        onClick={closeDropdown}
        key={apiKey.id}
        className={cx({
          [styles.activeKey]: currentKey?.id === apiKey.id,
          [styles.link]: true,
        })}>
        {apiKey.name}
      </Link>
    ),
    [closeDropdown, currentKey?.id],
  );

  const overlay = useMemo(
    () => (
      <WithSkeletonLoader isLoading={appConfigIsLoading} skeletonLoader={<SidebarAppSkeleton />}>
        <div
          className={cx({
            [styles.dropdown]: true,
            [styles.dropdownContent]: true,
            [styles.buttonWithBorder]: apiKeysResponse?.data.length,
          })}>
          <button
            disabled={
              (apiKeysResponse?.data && apiKeysPlanCount <= apiKeysResponse?.data?.length) || error
            }
            onClick={() => {
              setShowCreateKeyModal(true);
              closeDropdown();
            }}>
            <WithSpacing placement="right" spaceSize="small">
              <PlusIcon />
            </WithSpacing>
            Create new app
          </button>
          {(apiKeysResponse?.data || []).map(renderApiKey)}
        </div>
      </WithSkeletonLoader>
    ),
    [appConfigIsLoading, apiKeysPlanCount, apiKeysResponse, error, renderApiKey, closeDropdown],
  );

  const closeModal = () => setShowCreateKeyModal(false);
  const isAppsActive = location.pathname.includes(APP_VIEW_ROUTE);

  return (
    <>
      <ModalCreateApp onClose={closeModal} visible={showCreateModalKey} />
      <div className={styles.sidebar}>
        <div className={styles.wrapper}>
          <NavLink
            to={HOME_ROUTE}
            className={({ isActive }) => (isActive ? styles.activeLink : undefined)}>
            Watch dashboard
          </NavLink>
          <Dropdown
            getPopupContainer={(e) => {
              return e.parentElement?.parentElement as HTMLElement;
            }}
            placement="bottomRight"
            onVisibleChange={setDropdownVisible}
            visible={dropdownVisible}
            overlay={overlay}>
            <button
              className={cx({
                [styles.appsButton]: true,
                [styles.appsButtonColored]: dropdownVisible,
                [styles.active]: isAppsActive,
              })}>
              Apps
              <WithSpacing placement="left" spaceSize="small">
                <ArrowRight className={cx({ [styles.rotate]: dropdownVisible })} />
              </WithSpacing>
            </button>
          </Dropdown>
          <NavLink
            to={PRICING_ROUTE}
            className={({ isActive }) => (isActive ? styles.activeLink : undefined)}>
            Pricing
            {user?.subscription && (
              <Label small color={user.subscription.color}>
                {user.subscription.name}
              </Label>
            )}
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default SideBar;
