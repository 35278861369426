import React, { useCallback, useMemo } from 'react';

import { useGetPaymentsQuery } from 'services/userService';

import Button from 'components/button';
import WithLoader from 'components/with-loader';

import { ReactComponent as NoDataIcon } from 'assets/icons/no-data-icon.svg';

import { getCurrentPlan, userHasPlan } from 'utils/payment.utils';

import styles from './Pricing.module.scss';
import DefaultPricingView from './default-pricing-view';
import MySubscription from './my-subscription';

const Pricing = () => {
  const {
    data: payments = [],
    isLoading: paymentsIsLoading,
    isError: paymentsError,
    refetch: paymentsRefetch,
  } = useGetPaymentsQuery();

  const userCurrentPlan = getCurrentPlan(payments);

  const view = userHasPlan(userCurrentPlan) ? <MySubscription /> : <DefaultPricingView />;
  // const view = userCurrentPlan ? <DefaultPricingView /> : <MySubscription />;

  const handleRerunRequest = useCallback(() => {
    paymentsRefetch();
  }, [paymentsRefetch]);

  const errorContent = useMemo(
    () => (
      <div className={styles.errorWrapper}>
        <NoDataIcon />
        <div>It looks like the your payments didn’t load correctly.</div>
        <div>Please refresh or try again later.</div>
        <Button
          handleClick={handleRerunRequest}
          fullWidth={false}
          size="medium"
          styleType="outlinedViolet">
          Refresh
        </Button>
      </div>
    ),
    [handleRerunRequest],
  );

  return (
    <div className={styles.wrapper}>
      <WithLoader isLoading={paymentsIsLoading}>{paymentsError ? errorContent : view}</WithLoader>
    </div>
  );
};

export default Pricing;
