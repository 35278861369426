import React from 'react';

import Skeleton from 'components/skeleton';

import styles from './AppCard.module.scss';

export const AppActionButtonsSkeleton = () => {
  return (
    <div className={styles.buttonsSkeleton}>
      <Skeleton style={{ width: 108, height: 40, borderRadius: 12 }} />
      <Skeleton style={{ width: 83, height: 40, borderRadius: 12 }} />
    </div>
  );
};
