import React, { useCallback, useMemo } from 'react';

import Button from 'components/button/button';
import WithSkeletonLoader from 'components/with-skeleton-loader';
import WithSpacing from 'components/with-spacing';

import { Primitive } from 'types/baseTypes';

import { ReactComponent as NoDataIcon } from 'assets/icons/no-data-icon.svg';

import styles from './AppStatisticCard.module.scss';
import AppStatisticCardSkeleton from './appStatisticCard.skeleton';

type StatisticElement = {
  label: string;
  value?: Primitive;
};

interface AppStatisticCardProps {
  isLoading: boolean;
  isError: boolean;
  handleRerunRequest: () => void;
  elements: StatisticElement[];
}

const AppStatisticCard: React.FC<AppStatisticCardProps> = ({
  isLoading,
  elements,
  isError,
  handleRerunRequest,
}) => {
  const elementsRenderer = useCallback(
    ({ label, value }: StatisticElement) => (
      <div key={label}>
        <WithSpacing spaceSize="small">
          <span>{label}</span>
        </WithSpacing>
        <WithSkeletonLoader isLoading={isLoading} skeletonLoader={<AppStatisticCardSkeleton />}>
          <div>{value}</div>
        </WithSkeletonLoader>
      </div>
    ),
    [isLoading],
  );

  const cardsError = useMemo(
    () => (
      <div className={styles.errorWrapper}>
        <NoDataIcon />
        <div>It looks like the statistic didn’t load correctly.</div>
        <div>Please refresh or try again later.</div>
        <Button
          handleClick={handleRerunRequest}
          fullWidth={false}
          size="medium"
          styleType="outlinedViolet">
          Refresh
        </Button>
      </div>
    ),
    [handleRerunRequest],
  );

  if (isError && !isLoading) {
    return cardsError;
  }

  return <div className={styles.wrapper}>{elements.map(elementsRenderer)}</div>;
};

export default AppStatisticCard;
