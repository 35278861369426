import React from 'react';

import cx from 'classnames';

import styles from './Label.module.scss';

interface LabelProps {
  small?: boolean;
  color?: 'yellow' | 'purple';
  children: string | React.ReactElement;
}

const Label: React.FC<LabelProps> = ({ small, children, color = 'purple' }) => {
  return (
    <div
      className={cx({
        [styles.label]: true,
        [styles[color]]: color,
        [styles.small]: small,
      })}>
      {children}
    </div>
  );
};

export default Label;
