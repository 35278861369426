import React, { useCallback, useState } from 'react';

import { Select as SelectAntd } from 'antd';
import cx from 'classnames';

import Icon from 'components/icon';
import SelectOption from 'components/select-option';

import { SelectOptionType } from 'types/selectOptionTypes';

import ArrowBottom from 'assets/icons/arrow-bottom.svg';

import 'antd/lib/select/style/css';

import styles from './SingleSelect.module.scss';

type Primitive = boolean | string | number;

interface SelectProps {
  defaultValue?: Primitive | null;
  label?: string;
  placeholder?: string;
  value?: Primitive | { label: string; value: string };
  disabled?: boolean;
  handleChange?: (value: Primitive | { label: string; value: string }) => void;
  options: SelectOptionType[] | Primitive[];
  fullWidth?: boolean;
  defaultOpen?: boolean;
  notFoundContent?: React.ReactElement;
}

// Antd shows placeholder only if value === undefined, so we need some additional checks
const checkIsValueCorrect = (val: Primitive | undefined): Primitive | undefined => {
  if (typeof val === 'string') {
    return val || undefined;
  }
  if (typeof val === 'number') {
    return val ?? undefined;
  }

  return val;
};

const SingleSelect: React.FC<SelectProps> = ({
  placeholder,
  value,
  handleChange,
  options = [],
  defaultValue,
  disabled,
  fullWidth = true,
  defaultOpen = false,
  ...otherValues
}) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const valueToSet = typeof value === 'object' ? value?.value : value;
  const optionsToShow =
    typeof options[0] === 'object' ? options : options.map((e) => ({ label: e, value: e }));

  const setFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleKeyDown = useCallback((e: { key: string }) => {
    e.key === 'Tab' && setIsFocused(false);
  }, []);

  const onDropdownVisibilityChange = useCallback((e: boolean) => {
    setIsFocused(e);
    setIsDropdownOpened(e);
  }, []);

  return (
    <SelectAntd
      onKeyDown={handleKeyDown}
      onFocus={setFocus}
      bordered={false}
      onDropdownVisibleChange={onDropdownVisibilityChange}
      dropdownClassName={styles.dropdown}
      suffixIcon={
        <img
          src={ArrowBottom}
          alt="arrow"
          className={cx({
            [styles.arrow]: true,
            [styles.arrowRotated]: isDropdownOpened,
          })}
        />
      }
      className={cx({
        [styles.select]: true,
        [styles.focused]: isFocused,
        [styles.fullWidth]: fullWidth,
      })}
      value={checkIsValueCorrect(valueToSet)}
      disabled={disabled}
      onSelect={handleChange}
      placeholder={placeholder}
      defaultValue={defaultValue}
      defaultOpen={defaultOpen}
      showArrow
      {...otherValues}>
      {(optionsToShow as SelectOptionType[]).map((option, index) => (
        <SelectOption
          key={index}
          label={option.label}
          value={option.value}
          className={styles.option}>
          <div style={{ display: 'flex' }}>
            {option.icon && (
              <Icon
                src={option.icon}
                className={styles.optionIcon}
                alt="option icon"
                size="large"
              />
            )}
            <span>{option.label}</span>
          </div>
        </SelectOption>
      ))}
    </SelectAntd>
  );
};

export default SingleSelect;
