import React from 'react';

import AppView from 'pages/app-view';
import Home from 'pages/home';
import Login from 'pages/login';
import Pricing from 'pages/pricing';
import ComparePlans from 'pages/pricing/compare-plans';
import { Navigate } from 'react-router-dom';

export type RouteType = {
  path: string;
  element: React.ReactElement;
};

export const HOME_ROUTE = '/home';
export const PRICING_ROUTE = '/pricing';
export const COMPARE_PLANS_ROUTE = `${PRICING_ROUTE}/plans`;
export const LOGIN_ROUTE = '/login';
export const SIGNUP_ROUTE = '/signup';
export const APP_VIEW_ROUTE = '/app';
export const APP_VIEW_WITH_ID_ROUTE = `${APP_VIEW_ROUTE}/:id`;

export const UNKNOWN_ROUTE = '*';

export const getAppWithIdRoute = (id: string): string => `${APP_VIEW_ROUTE}/${id}`;
export const AUTH_ROUTES: RouteType[] = [
  { path: HOME_ROUTE, element: <Home /> },
  { path: PRICING_ROUTE, element: <Pricing /> },
  { path: COMPARE_PLANS_ROUTE, element: <ComparePlans /> },
  { path: APP_VIEW_WITH_ID_ROUTE, element: <AppView /> },
  { path: UNKNOWN_ROUTE, element: <Navigate replace to={HOME_ROUTE} /> },
];

export const NON_AUTH_ROUTES: RouteType[] = [
  { path: LOGIN_ROUTE, element: <Login /> },
  { path: UNKNOWN_ROUTE, element: <Navigate replace to={LOGIN_ROUTE} /> },
];
