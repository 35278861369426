import React from 'react';

import {
  notificationNotificationTypeSelector,
  notificationShowDefaultNotificationSelector,
  notificationShowErrorSelector,
} from 'selectors/notificationSlice.selectors';
import { useAppSelector } from 'store';

import NotificationError from 'containers/notification-error';
import NotificationRequestError from 'containers/notification-request-error';
import NotificationSuccess from 'containers/notification-success';

const AppNotification = () => {
  const showError = useAppSelector(notificationShowErrorSelector);
  const showDefaultNotification = useAppSelector(notificationShowDefaultNotificationSelector);
  const notificationType = useAppSelector(notificationNotificationTypeSelector);

  if (showError) {
    return <NotificationRequestError />;
  }

  if (showDefaultNotification) {
    return notificationType === 'success' ? <NotificationSuccess /> : <NotificationError />;
  }

  return null;
};

export default AppNotification;
